import {ChargeModel} from "../charge/ChargeTypes";
import {SoftDeleteModel} from "../../lib/samfe/types/ModelTypes";
import {WithCount} from "../generic/ModelTypeExtensions";

export type ProfitLossRelationsBluePrint = 'charge'

export type ProfitLossCause = 'Reference-material' | 'Write-off' | 'Waste' | 'Delivery' | 'Miscounted' | 'Other'

export const ProfitLossCauses: ProfitLossCause[] = [
    'Reference-material',
    'Write-off',
    'Waste',
    'Miscounted',
    'Other'
];

export const ProfitLossCauseDisplayName = (cause?: ProfitLossCause): string => {
    switch (cause) {
        case 'Reference-material'    : return 'Referentiemateriaal';
        case 'Write-off'    : return 'Afboeken';
        case 'Waste'        : return 'Verspilling';
        case 'Miscounted'   : return 'Voorraadcorrectie';
        case 'Other'        : return 'Anders';
        default             : return 'Onbekend';
    }
}


export type ProfitLossFields = {
    charge_id?: number,
    amount?: number,
    cause?: ProfitLossCause,
    comment?: string,
}

export type ProfitLossDto = Partial<ProfitLossFields>

export type ProfitLossRelations = {
    charge: ChargeModel
}

export type ProfitLossModel = SoftDeleteModel
    & Partial<ProfitLossRelations>
    & WithCount<Partial<ProfitLossRelations>>
    & ProfitLossDto
