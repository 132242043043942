import React, { FC } from 'react';
import { useForm } from '../../lib/samfe/components/Form';
import useAsyncInit from '../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../lib/samfe/components/Form/Effects/useSchema';
import Yup from '../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../lib/samfe/components/Modal/FormModal/FormModal';
import ProductTypeForm from '../productType/ProductTypeForm';
import useProductType from '../productType/useProductType';
import { ProductDto, ProductModel, ProductRelationsBluePrint } from './ProductTypes';
import useProduct from './useProduct';


const ProductForm: FC<ExtendFormModalProps<ProductDto>> = ({ id, open, setOpen, onSuccess }): JSX.Element => {

    const product = useProduct();


    /**
     *
     */
    const shape = (productModel?: ProductModel): Shape<ProductDto> => ({

        name: Yup.string()
            .required()
            .label('Product')
            .controlType('input')
            .inputType('text'),

        subtitle: Yup.string()
            .label('Ondertitel')
            .controlType('textArea')
            .inputType('text'),


        number: Yup.number()
            .hidden(true)
            .label('Productnummer')
            .controlType('input')
            .inputType('number'),


        product_type_id: Yup.string()
            .required()
            .label('Type')
            .controlType('selectSearch')
            .selectSearchConfig({
                initialModel: productModel?.productType,
                expectsInitialModel: id !== undefined,
                useHook: useProductType,
                searchOptions: {
                    searchCols: [ 'name' ],
                    valueCol: 'id',
                    limit: 'all',
                    // @fixme monkey patch for limit all
                    filter: 'archived=0',
                    displayName: model => `${ model.name }`,
                    FormModal: ProductTypeForm
                }
            }),

        version: Yup.number()
            .required()
            .label('Productversie')
            .hidden(productModel?.id !== undefined)
            .controlType('input')
            .inputType('number')
            .min(0)
            .steps(0.1)
            .defaultValue(productModel?.version ?? 1),

        risk_substantiation: Yup.string()
            .hidden(true)
            .label('risk_substantiation')
            .controlType('input')
            .inputType('text'),

        comments: Yup.string()
            .label('Product notitie')
            .controlType('textArea')
    });


    /**
     *
     */
    const { setShape, validationSchema } = useSchema<ProductDto>(shape());

    const initializer = async() => {
        const initialProduct = await product.getItem(id, { with: [ 'productType' ] });
        setShape(shape(initialProduct));
    };

    /**
     *
     */
    const { formikConfig, formFields } = useForm<ProductModel, ProductDto, ProductRelationsBluePrint>({
        id,
        validationSchema,
        useHttpHook: useProduct,
        onSuccess: onSuccess,
        initializer,
        initialized: useAsyncInit(initializer, open)
    });


    return <FormModal
        id={ id }
        resource={ 'Product' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
    />;
};

export default ProductForm;
