import React, { FC, useMemo } from 'react';
import Table from '../../../../components/table';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import { TableColumns } from '../../../../components/table/types/TableRow';
import { ProducerStandardModel, ProducerStandardRelationsBluePrint } from './ProducerStandardTypes';
import useProducerStandard from './useProducerStandard';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ProducerStandardTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useProducerStandard(parentId);


    const rows = useMemo((): TableColumns<ProducerStandardModel, ProducerStandardRelationsBluePrint>[] => [
        {
            header: {
                children: 'Standaard'
            },
            column: (item) => ({
                children: item.standard?.name
            }),
            type: 'text'
        }
    ], []);

    return <Table
        id={ 'producer-standards' }
        rows={ rows }
        rowActions={ {
            linkTo: (item) => `/settings/producer-standards/${ item.standard_id }`
        } }
        http={ {
            hook: httpHook,
            searchCols: [ 'standard.name' ],
            with: [ 'standard' ]
        } }
        forms={ {
            // archive: {
            //     id: (record) => record.id,
            //     itemName: (record) => `${ record.standard?.name }`,
            //     resourceName: () => 'Industriestandaard'
            // }
        } }
        styling={{
          variation: 'inline'
        }}
    />;
};
export default ProducerStandardTable;