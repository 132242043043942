import classNames from 'classnames';
import React, { FC, useMemo } from 'react';
import Table from '../../../../components/table';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import { DefaultTableFilter } from '../../../../components/table/types/TableFilter';
import { TableSearchCol } from '../../../../components/table/types/TableHttp';
import { TableColumns } from '../../../../components/table/types/TableRow';
import useFileHandler from '../../../document/useFileHandler';
import CustomerArticleLabelForm from './CustomerArticleLabelForm';

import { CustomerArticleLabelModel, CustomerArticleLabelRelationsBluePrint } from './CustomerArticleLabelTypes';
import useCustomerArticleLabel from './useCustomerArticleLabel';


type BaseProps = Required<Omit<ExtendTableProps, 'notification'|'htmlBefore'|'parentId'>>&{
    customerId?: undefined;
    withoutFiles?: undefined;
    saleId?: undefined;
}

type Props =
    Omit<BaseProps, 'customerId'>&{ customerId: number }
    |Omit<BaseProps, 'withoutFiles'>&{ withoutFiles: true }
    |Omit<BaseProps, 'saleId'>&{ saleId: number }

const CustomerArticleLabelTable: FC<Props> = ({ customerId, withoutFiles, saleId }): JSX.Element => {

    const httpHook = useCustomerArticleLabel();

    const { downloadFile, viewFile } = useFileHandler();

    type RowType = TableColumns<CustomerArticleLabelModel, CustomerArticleLabelRelationsBluePrint>
    const rows = useMemo((): RowType[] => {

        const columns: RowType[] = [];

        if (saleId) {
            columns.push({
                header: {
                    children: 'Aantal'
                },
                column: (item) => ({
                    children: item.quantity
                }),
                type: 'numeric',
                style: {
                    width: '8rem'
                }
            });
        }

        if (withoutFiles) {
            columns.push(
                {
                    header: {
                        children: 'Pakbon',
                        sortCol: 'sale_id'
                    },
                    column: (item) => ({
                        children: item.sale_id,
                        linkTo: (item) => `/sales/${ item.sale_id }`
                    }),
                    type: 'id'
                },
                {
                    header: {
                        children: 'Klant'
                    },
                    column: (item) => ({
                        children: `[${ item.customer?.number }] ${ item.customer?.company }`
                    }),
                    type: 'text-raw'
                },
                {
                    header: {
                        children: 'Verzenddatum',
                        sortCol: 'shipped_at'
                    },
                    column: (item) => ({
                        children: item.shipped_at
                    }),
                    type: 'date'
                }
            );
        }

        columns.push(
            {
                header: {
                    children: 'Product'
                },
                column: (item) => ({
                    children: item.product?.name
                }),
                type: 'text-raw'
            },
            {
                header: {
                    children: 'Artikel'
                },
                column: (item) => ({
                    children: item.article?.number
                }),
                type: 'id'
            },
            {
                header: {
                    children: 'Productversie'
                },
                column: (item) => ({
                    children: `V${ item.product?.version }`
                }),
                type: 'id'
            },
            {
                header: {
                    children: 'Labelversie'
                },
                column: (item) => ({
                    children: `V${ item.labelVersion?.version }`
                }),
                type: 'id'
            },
            {
                header: {
                    children: 'Labelformaat',
                    sortCol: 'label_format_id'
                },
                column: (item) => {

                    let children = '-';
                    if (item.file_id != undefined) {
                        const labelId = item.label_format_id ?? 6;
                        const labelWidth = item.label_width ?? 0;
                        const labelHeight = item.label_height ?? 0;
                        children = `${ labelId } (${ labelHeight }x${ labelWidth })`;
                    }

                    return {
                        children
                    };
                },
                type: 'text-raw'
            }
        );

        if (!withoutFiles) {
            columns.push({
                type: 'text-raw',
                header: { children: 'Bestand' },
                column: (item) => ({
                    children: item.file_id != undefined
                              ?`${ item.file?.name ?? '-' }${ item.file?.extension ?`.${ item.file.extension }` :'' }`
                              :'-'
                })
            });
        }

        return columns;
    }, [ withoutFiles, saleId ]);


    type SearchCol = TableSearchCol<CustomerArticleLabelModel, CustomerArticleLabelRelationsBluePrint>
    const searchCols = useMemo((): SearchCol[] => {
        const searchCols: SearchCol[] = [ 'product.number', 'product.name', 'article.number' ];
        if (withoutFiles) {
            searchCols.push('customer.number', 'customer.company');
        }
        return searchCols;
    }, [ withoutFiles ]);


    const withRelations = useMemo((): CustomerArticleLabelRelationsBluePrint[] => {
        const withRelations: CustomerArticleLabelRelationsBluePrint[] = [ 'article', 'product', 'labelVersion', 'file' ];
        if (withoutFiles) {
            withRelations.push('customer');
        }
        return withRelations;
    }, [ withoutFiles ]);


    type Filter = DefaultTableFilter<CustomerArticleLabelModel>
    const filter = useMemo((): Filter|undefined => {
        if (customerId) {
            return {
                column: 'customer_id',
                operator: '=',
                value: customerId
            };
        }

        if (saleId) {
            return {
                column: 'sale_id',
                operator: '=',
                value: saleId
            };
        }
        return undefined;
    }, [ customerId, saleId ]);

    const doesntHave = useMemo((): CustomerArticleLabelRelationsBluePrint[]|undefined => {
        if (withoutFiles) {
            return [ 'file' ];
        }
        return undefined;
    }, [ withoutFiles ]);

    return <>
        <Table
            id={ 'customer-article-labels' }
            rows={ rows }
            rowClassName={ item => classNames(!withoutFiles && !item.file && '!bg-fire/15 hover:!bg-fire/20') }
            http={ {
                hook: httpHook,
                searchCols,
                with: withRelations,
                sortCol: customerId ? 'id' : 'sale_id',
                sortDir: customerId ? 'ASC' : 'DESC',
                filterConfig: {
                    hideAllFilters: true
                },
                doesntHave,
                filter
            } }
            styling={ {
                variation: 'inline'
            } }

            forms={ {
                actions: [
                    {
                        hideForRow: (item) => !!item.file_id,
                        title: 'Uploaden',
                        Form: CustomerArticleLabelForm,
                        parentId: (record) => record.customer_id,
                        id: (record) => record.id
                    },
                    {
                        hideForRow: (item) => !item.file_id,
                        title: 'Aanpassen',
                        Form: CustomerArticleLabelForm,
                        parentId: (record) => record.customer_id,
                        id: (record) => record.id
                    }
                ]
            } }

            callbacks={ [
                {
                    title: 'Bekijken',
                    hideForRow: (item) => !item.file_id,
                    onClick: (item) => {
                        if (item.file) {
                            viewFile(item.file);
                        }
                    }
                },
                {
                    title: 'Downloaden',
                    hideForRow: (item) => !item.file_id,
                    onClick: (item) => {
                        if (item.file) {
                            downloadFile(item.file);
                        }
                    }
                }
            ] }
        />
    </>;

};

export default CustomerArticleLabelTable;