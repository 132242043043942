import React, { FC, useMemo } from 'react';
// import OverviewForm from './OverviewForm';
import { OverviewModel, OverviewRelationsBluePrint } from './OverviewTypes';
import useOverview from './useOverview';
import {ExtendTableProps} from "../../components/table/types/Table";
import Table from "../../components/table";
import {TableColumns} from "../../components/table/types/TableRow";
import { datetimeToLocale } from '../../lib/samfe/modules/Parse/Date';

type Props = Required<ExtendTableProps>

const OverviewTable: FC<Props> = () => {

    const httpHook = useOverview();

    const rows = useMemo((): TableColumns<OverviewModel, OverviewRelationsBluePrint>[] => [
        {
            header: {
                children: 'Order nummer',
                sortCol: 'id'
            },
            column: (item) => ({	
                children: item.id,
                linkTo: (item) => `/sales/${item.id}`
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Besteldatum',
            },
            column: (item) => ({	
                children: datetimeToLocale( item.created_at )
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Bedrijf',
            },
            column: (item) => ({	
                children: item.company,
                linkTo: (item) => `/customers/${item.customer_id}`
            }),
            type: 'text'
        },
		{
            header: {
                children: 'Artikel',
            },
            column: (item) => ({
                children: item.sku,
                linkTo: (item) => `/articles/${item.article_id}`
            }),
            type: 'text',
        },
		{
            header: {
                children: 'Product',
            },
            column: (item) => ({
                children: item.name,
                linkTo: (item) => `/products/${item.product_id}`
            }),
            type: 'text',
        },
		{
            header: {
                children: 'Hoeveelheid',
            },
            column: (item) => ({
                children: item.quantity
            }),
            type: 'numeric',
        },
		{
            header: {
                children: 'Prijs per stuk',
            },
            column: (item) => ({
                children: item.price_per_amount
            }),
            type: 'currency',
        },
		{
            header: {
                children: <span className={ 'text-right' }>
                <span className={ 'block -mb-1' }>Regeltotaal</span>
                <small>(Hoeveelheid * Prijs per stuk)</small>
            </span>,
            },
            column: (item) => ({
                children: ( item.price_per_amount ?? 0 ) * ( item.quantity ?? 0 )
            }),
            type: 'currency',
        },
        
    ], []);

    return (<>
        <Table
            id={ 'Overview' }
            rows={ rows }
            http={ {
                hook: httpHook,
                searchCols: [ 'id', 'company' ],
				sortCol: 'id',
                sortDir: 'DESC',

                // filter: {
                //   column: 'active',
                //   value: 1
                // },
                // filters: [
                //     {
                //         displayName: 'Actief',
                //         column: 'active',
                //         value: 1
                //     },
                //     {
                //         displayName: 'Inactief',
                //         column: 'active',
                //         value: 0
                //     }
                // ],
            } }
            // forms={ {
            // } }
        />
    </>
    );
};

export default OverviewTable;
