import React, { useMemo } from 'react';
import useTablePagination from '../../hooks/useTablePagination';
import useTableScrollToTop from '../../hooks/useTableScrollToTop';
import { useTableContext } from '../../providers/Table.provider';
import PaginationDividerComponent from '../PaginationDivider.component';
import PaginationNavigationButtonComponent from '../PaginationNavigationButton.component';
import PaginationPageButtonComponent from '../PaginationPageButton.component';


const PaginationGroupComponent = <T extends object, R extends string>() => {

    const [ pagination, setPagination ] = useTablePagination<T, R>();
    const { http } = useTableContext<T, R>();

    const scrollToTop = useTableScrollToTop()

    const showAll = useMemo(() => {
        return http.showAll ?? false;
    }, [ http.showAll ]);

    /**
     * Total possible indexes, rounded tot top for remainder items.
     */
    const totalIndexes: number = useMemo(() => {
        const indexes = Math.ceil(pagination.totalItems / (pagination.perPage));
        return indexes - 1<=0 ?0 :indexes - 1;
    }, [ pagination.perPage, pagination.totalItems ]);


    /**
     * Pagination starts at 0, we call this index.
     */
    const currentIndex: number = useMemo(() => {
        return pagination.currentPage;
    }, [ pagination.currentPage ]);


    /**
     * Check if index = 0 (or less, should not occur).
     */
    const isFirstIndex: boolean = useMemo(() => {
        return currentIndex<=0;
    }, [ currentIndex ]);

    /**
     * Check if index = index max (or more, should not occur).
     */
    const isLastIndex: boolean = useMemo(() => {
        return currentIndex>=totalIndexes;
    }, [ currentIndex, totalIndexes ]);

    /**
     * Cast current index to page -> index + 1
     */
    const currentPage: number = useMemo(() => {
        return currentIndex + 1;
    }, [ currentIndex ]);

    /**
     * Cast max index to page -> max index + 1
     */
    const totalPages: number = useMemo(() => {
      return totalIndexes + 1;
    }, [ totalIndexes ]);


    /**
     * Algorithm for button display logic.
     *
     * Covers cases depending on current page and max pages.
     * Every state has an ASCII art representation + notation of the outcome.
     *
     * Goal: Maximum of 9 items on the screen (nav back + buttons + dividers + nav next) <= 9
     *
     * number = button towards page
     * null = divider
     */
    const buttonSequence: (number|null)[] = useMemo(() => {

        // State 1
        // [1] [2*] [3*] [4*] [5*] [6*] [max<8*]
        if (totalPages<8) {
            return Array.from(Array(totalPages)).map((_, i) => i + 1);
        }

        // State 2
        // [1] [2] [3] [4] [5] [...] [8]
        if (totalPages == 8 && currentPage<5) {
            return [ 1, 2, 3, 4, 5, null, 8 ];
        }

        // State 3
        // [1] [...] [4] [5] [6] [7] [8]
        if (totalPages == 8 && currentPage>4) {
            return [ 1, null, 4, 5, 6, 7, 8 ];
        }

        // State 4
        // [1] [2] [3] [4] [5] [...] [9]
        if (totalPages == 9 && currentPage<5) {
            return [ 1, 2, 3, 4, 5, null, 9 ];
        }

        // State 5
        // [1] [...] [4] [5] [6] [...] [9]
        if (totalPages == 9 && currentPage == 5) {
            return [ 1, null, 4, 5, 6, null, 9 ];
        }

        // State 6
        // [1] [...] [5] [6] [7] [8] [9]
        if (totalPages == 9 && currentPage>5) {
            return [ 1, null, 5, 6, 7, 8, 9 ];
        }

        // State 7
        // [1] [2] [3] [4] [5] [...] [max>9]
        if (totalPages>9 && currentPage<5) {
            return [ 1, 2, 3, 4, 5, null, totalPages ];
        }

        // State 8
        // [1] [...] [n-1] [4 < n < max-3 ] [n+1] [...] [max>9]
        if (totalPages>9 && currentPage>4 && currentPage<totalPages - 3) {
            return [ 1, null, currentPage - 1, currentPage, currentPage + 1, null, totalPages ];
        }

        // State 9
        // [1] [...] [max-4] [max-3] [max-2] [max-1] [max>9]
        if (totalPages>9 && currentPage>totalPages - 4) {
            return [ 1, null, ...Array.from(Array(5)).map((_, i) => totalPages - i).reverse() ];
        }

        // State 10: fallback, should not occur
        // [n]
        return [ currentPage ];
    }, [ totalPages, currentPage ]);


    const handlePageChange = (index: number) => {
        setPagination({
            ...pagination,
            currentPage: index
        });
        scrollToTop()
    };


    return (
        <>
            { !showAll && <div className={ 'flex gap-x-2' }>
                <PaginationNavigationButtonComponent direction={ 'back' } onClick={ () => handlePageChange(currentIndex - 1) } disabled={ isFirstIndex }/>
                { buttonSequence.map((page, i) =>
                    page !== null
                    ?<PaginationPageButtonComponent
                        key={ i }
                        onClick={ () => handlePageChange(page - 1) }
                        active={ currentPage == page }
                    >
                        { page }
                    </PaginationPageButtonComponent>
                    :<PaginationDividerComponent key={ i }/>
                ) }
                <PaginationNavigationButtonComponent direction={ 'next' } onClick={ () => handlePageChange(currentIndex + 1) } disabled={ isLastIndex }/>
            </div> }
        </>
    );
};

export default PaginationGroupComponent;
