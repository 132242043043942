import React, { FC, useMemo } from 'react';
import Table from '../../components/table';
import { ExtendTableProps } from '../../components/table/types/Table';
import { TableFilterType } from '../../components/table/types/TableFilter';
import { TableColumns } from '../../components/table/types/TableRow';
import useAsyncMemo from '../../lib/samfe/hooks/useAsyncMemo';
import { GroupModel } from '../group/GroupTypes';
import useGroup from '../group/useGroup';
import LocationForm from './LocationForm';
import { LocationModel, LocationRelationsBluePrint } from './LocationTypes';
import { useLocation } from './useLocation';


type Props = Required<ExtendTableProps>

const LocationTable: FC<Props> = (): JSX.Element => {

    const httpHook = useLocation();
    const group = useGroup();

    const groups: GroupModel[] = useAsyncMemo(async() => {
        return await group.getList({ limit: 'all', orderBy: 'key' });
    }, [], []);

    const rows = useMemo((): TableColumns<LocationModel, LocationRelationsBluePrint>[] => [
        {
            header: {
                children: 'Pand'
            },
            column: (item) => {
                if (!item.group) {
                    return {
                        children: <span className={ 'text-fire font-medium' }>Oude locatie</span>
                    };
                }

                if (!item.group?.key) {
                    return {
                        children: <>
                            <p>{ item.group?.name }</p>
                            <small>Geen korte naam aanwezig</small>
                        </>
                    }
                }

                return {
                    children: item.group?.key
                }
            },
            type: 'text'
        },
        {
            header: {
                children: 'Stelling'
            },
            column: (item) => ({
                children: item.name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Aantal vakken bezet',
                sortCount: 'charges'
            },
            column: (item) => ({
                children: item.charges_count
            }),
            type: 'numeric'
        }
    ], []);


    return <>
        { groups.length>0 && <Table
            id={ 'locations' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `${ item.id }`
            } }
            http={ {
                hook: httpHook,
                sortCol: 'name',
                sortDir: 'ASC',
                searchCols: [ 'name' ],
                with: [ 'group' ],
                withCount: [ 'charges' ],
                filters: groups.map(group => (
                    {
                        column: 'group_id',
                        displayName: group.key ?? group.name,
                        value: group.id
                    } as TableFilterType<LocationModel>
                ))
            } }
            forms={ {
                edit: {
                    Form: LocationForm,
                    id: (item) => item.id
                },
                // archive: {
                //     id: (item) => item.id,
                //     resourceName: () => 'Locatie',
                //     itemName: (item) => item.name,
                //     disableForRow: (item) => (item.charges_count ?? 0)>0
                // }
            } }
            styling={{
              variation: 'inline'
            }}
        /> }
    </>;
};

export default LocationTable;