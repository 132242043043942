import React, { FC, useMemo } from 'react';
import Table from '../../components/table';
import { ExtendTableProps } from '../../components/table/types/Table';
import { TableColumns } from '../../components/table/types/TableRow';
import RiskAssuranceForm from './RiskAssuranceForm';
import { RiskAssuranceModel, RiskAssuranceRelationsBluePrint } from './RiskAssuranceTypes';
import useRiskAssurance from './useRiskAssurance';


type Props = ExtendTableProps

const RiskAssuranceTable: FC<Props> = () => {

    const httpHook = useRiskAssurance();

    const rows = useMemo((): TableColumns<RiskAssuranceModel, RiskAssuranceRelationsBluePrint>[] => [
        {
            header: {
                children: 'Risicoborging',
                sortCol: 'name'
            },
            column: (item) => ({
                children: item.name
            }),
            type: 'text'
        }
    ], []);

    return (
        <Table
            id={ 'risk-assurances' }
            rows={ rows }
            http={ {
                hook: httpHook,
                searchCols: [ 'name' ],
                sortCol: 'name',
                sortDir: 'ASC'
            } }
            forms={ {
                edit: {
                    Form: RiskAssuranceForm,
                    id: (record) => record.id
                },
                // archive: {
                //     id: (record) => record.id,
                //     itemName: (record) => `${ record.name }`,
                //     resourceName: () => 'Risicoborging'
                // }
            } }
            styling={{
              variation: 'inline'
            }}
        />
    );
};

export default RiskAssuranceTable;