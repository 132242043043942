import React from "react";
import exampleGraph from '../../../../assets/images/example-graph-02.jpeg'
import examplePieChart from '../../../../assets/images/exaple-pie-chart.jpeg'
import {classNames} from "../../modules/Parse/String";

type Props = {
    show?: boolean
}
const ExampleGraph: React.FC<Props> = ({show = false}): JSX.Element => <>
    <div className={classNames("relative opacity-50", !show && 'hidden' )}>
        <div className={'grid md:grid-cols-3 gap-4 blur-[2px]'}>
            <div className={'relative bg-white p-6 drop-shadow shadow-sm rounded-lg h-full w-full mt-8 md:col-span-2'}>
                <h2 className={'text-graphite text-2xl mb-6 font-medium'}>Trends</h2>
                <div className={''}>
                    <img className={'w-full'} src={exampleGraph} alt="example graph"/>
                </div>
            </div>
            <div className={'relative bg-white p-6 drop-shadow shadow-sm rounded-lg h-full w-full mt-16 md:mt-8 md:col-span-1'}>
                <h2 className={'text-graphite text-2xl mb-6 font-medium break-all'}>Samenstelling</h2>
                <div className={''}>
                    <img className={'w-2/5 md:w-4/5 mx-auto'} src={examplePieChart} alt="example chart"/>
                </div>
            </div>
        </div>
        <div className="absolute top-6 -right-2 -bottom-10 -left-2 bg-gradient-to-tl from-blue-900 to-blue-500 opacity-10 rounded-lg blur-md backdrop-blur"></div>
        <span className={'absolute font-semibold text-6xl text-white block w-full mx-auto top-[33.333%] text-center drop-shadow-lg !opacity-100'}>Coming soon</span>
    </div>
</>
export default ExampleGraph;