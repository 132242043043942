import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ActionButton, EditButton, SaveButton } from '../../lib/samfe/components/Button';
import Input from '../../lib/samfe/components/Form/Generic/Input';
import Select from '../../lib/samfe/components/Form/Generic/Select/Select';
import TextArea from '../../lib/samfe/components/Form/Generic/TextArea';
import Alert from '../../lib/samfe/components/Notifications/Alert';
import { datetimeToLocale, sqlDateTimeToDate } from '../../lib/samfe/modules/Parse/Date';
import { thousandSeparator } from '../../lib/samfe/modules/Parse/Number';
import { SortObjectArray } from '../../lib/samfe/modules/Parse/Object';
import { classNames } from '../../lib/samfe/modules/Parse/String';
import { ArticleModel } from '../article/ArticleTypes';
import useAttribute from '../attribute/useAttribute';
import { batchCodeLabel, expirationDateLabel } from '../charge/ChargeFunctions';
import { getPackageNameFromParts } from '../package/PackageFunctions';
import { ChargeModel } from '../charge/ChargeTypes';
import useAttributeProduct from '../product/pivot/attributeProduct/useAttributeProduct';
import { ProductModel } from '../product/ProductTypes';
import { getProductTypeUnit } from '../productType/ProductTypeFunctions';
import { ProductTypeModel } from '../productType/ProductTypeTypes';
import RepackData, { RepackDataType } from './RepackData';

import { RepackingModel, RepackingStatus, RepackStatusTranslation } from './RepackingTypes';
import useRepackingPivotless from './useRepackingPivotless';
import {parseChargeLocations} from "../../lib/samfe/modules/Parse/Location";


type Props = {
    repackOrder: RepackingModel,
    isInline?: boolean
    className?: string,
    editAction?: () => void
    bookInAction?: () => void,
    onClose?: () => void,
    isBookIn?: boolean,
}


type RepackReceiptDisplayItem = {
    type: 'Bron'|'Target',
    charge?: ChargeModel,
    batchCode?: string|JSX.Element,
    expirationDate?: string,
    article?: ArticleModel,
    amount: number,
    booked_target_quantity?: number
}

const RepackingReceipt: FC<Props> = ({ repackOrder, isBookIn = false, className, onClose, editAction, bookInAction, isInline = true }): JSX.Element => {


    const repackHttp = useRepackingPivotless();
    const attributes = useAttribute();
    const attributeProduct = useAttributeProduct(repackOrder.parentArticle?.product_id);
    const { productId, setProductId } = attributeProduct;

    const [ product, setProduct ] = useState<ProductModel>();
    const [ productType, setProductType ] = useState<ProductTypeModel>();
    const [ repackStatus, setRepackStatus ] = useState<RepackingStatus|undefined>(repackOrder.status);

    const [ allergeneId, setAllergeneId ] = useState<number>();
    const [ allergens, setAllergens ] = useState<string[]>([]);

    const repackData: RepackDataType = useMemo(() => RepackData(repackOrder), [ repackOrder ]);

    //noinspection com.intellij.reactbuddy.ExhaustiveDepsInspection
    const {
        parentArticle,
        targetArticle,
        parentCharge,
        targetCharge,
        amount,
        repack_date
    } = useMemo(() => {
        setProduct(repackOrder.parentArticle?.product);
        setProductType(repackOrder.parentArticle?.product?.productType);
        setProductId(repackOrder.parentArticle?.product_id);
        if (repackStatus !== repackOrder.status) {
            setRepackStatus(repackOrder.status);
        }

        return repackOrder;
    }, [ repackOrder ]);


    useEffect(() => {
        if ( allergeneId !== undefined ) {
            return;
        }
        attributes.getList({
            limit: 1,
            select: [ 'id' ],
            searchCols: [ 'name' ],
            search: 'allergenen'
        }).then(items => {
            if (items.length>0) {
                setAllergeneId(items[0].id);
            }
        });
    }, [ productId ]);


    useEffect(() => {
        if (!productId || !allergeneId) {
            return;
        }

        attributeProduct.getList({
            limit: 'all',
            filter: `attribute_id=${ allergeneId }`,
            with: [ 'option' ]
        }).then(aps => {
            setAllergens(aps.filter(ap => !(!ap.option?.name)).map(ap => ap.option!.name!));
        });
    }, [ productId, allergeneId ]);

    useEffect(() => {
        repackOrder.status = repackStatus;
    }, [ repackStatus ]);


    const parentItem: RepackReceiptDisplayItem = useMemo(() => ({
        type: 'Bron',
        charge: parentCharge,
        batchCode: parentArticle !== undefined
                   ?(parentCharge !== undefined ?batchCodeLabel(parentCharge) :'Backorder')
                   :undefined,
        expirationDate: parentCharge?.expiration_date,
        article: parentArticle,
        amount: repackData.parent.amount,
    }), [ parentCharge, parentArticle, repackData.parent.amount ]);

    const targetItem: RepackReceiptDisplayItem = useMemo(() => ({
        type: 'Target',
        charge: targetCharge,
        batchCode: targetCharge
                   ?(
                       repackOrder.targetCharge !== undefined
                       ?batchCodeLabel(repackOrder.targetCharge)
                       :(repackOrder?.batchcode ?? 'Onbekend')
                   )
                   :repackOrder.batchcode,
        expirationDate: repackOrder?.expiration_date,
        article: targetArticle,
        amount: Math.floor(repackData.target.amount),
        booked_target_quantity: repackOrder?.booked_target_quantity
    }), [ targetCharge, targetArticle, repackOrder?.batchcode, repackOrder.expiration_date, repackData.target.amount, repackOrder?.booked_target_quantity ]);


    const summarySkeleton = <Skeleton width={ 100 } inline={ true }/>;

    const getH2 = (children: ReactNode): JSX.Element => <h2 className={ 'text-aqua text-[1.05rem] font-semibold mb-1' }>{ children }</h2>;
    const getH3 = (children: ReactNode): JSX.Element => <h3 className={ 'text-aqua text-[0.95rem] font-semibold mb-1' }>{ children }</h3>;
    const getH4 = (children: ReactNode): JSX.Element => <h4 className={ 'text-aqua text-[0.85rem] font-medium mb-0.5' }>{ children }</h4>;
    const getP = (children: ReactNode): JSX.Element => <p className={ 'text-graphite text-sm' }>{ children ?? summarySkeleton }</p>;
    const getSpan = (children: ReactNode): JSX.Element => <span className={ 'text-graphite text-sm' }>{ children }</span>;

    const getHr = () => <hr className={ 'my-6' }/>;
    const getDisplayRowItem = (key: ReactNode, value: ReactNode) => <div className={ 'py-1' }>
        { getH3(key) }
        { getP(value) }
    </div>;

    const getUnit = (amount: number, isBulk: boolean) => isBulk
                                                         ?getProductTypeUnit(productType, amount !== 1)
                                                         :(amount === 1 ?'artikel' :'artikelen');

    const getDisplayItem = (displayData: RepackReceiptDisplayItem): JSX.Element => {

        const unit = getUnit(displayData.amount, displayData.article?.is_bulk === true);
        return <div className={ 'rounded-md py-4 px-6 shadow shadow-aqua/20' }>
            { getH2(displayData.type) }
            { getDisplayRowItem('Artikel', displayData.article?.number) }
            { getDisplayRowItem('Charge', <span>
                { displayData.batchCode }&nbsp;
                { parseChargeLocations(displayData.charge?.chargeLocations ?? []) }
            </span>) }
            { getDisplayRowItem('Aantal gewenst', `${ thousandSeparator(displayData.amount) } ${ unit }`) }
            { ( displayData.booked_target_quantity ?? 0 ) > 0 ? getDisplayRowItem('Aantal uitgevuld', `${ thousandSeparator(displayData?.booked_target_quantity) } artikelen`) : '' }
            { 
              ( displayData.amount ?? 0 ) && ( displayData.booked_target_quantity ?? 0 ) > 0 
              ? 
              getDisplayRowItem('Waste', `${ ( ( displayData.amount ?? 0 ) - ( displayData?.booked_target_quantity ?? 0 ) ) } artikelen` )
              : '' }
            { getDisplayRowItem('THT', expirationDateLabel(displayData.expirationDate)) }
            { getDisplayRowItem('Verpakking', getPackageNameFromParts( displayData.article?.package?.packageParts ) ) }

        </div>;
    };

    return <div className={ classNames(isInline ?'my-8 h-[58rem]' :'sm:w-[56rem]', 'p-8 shadow-aqua/40 shadow-md rounded-lg text-aqua bg-white all-children:text-left', className) }>

        <div className={ 'relative' }>

            { getH2(`Samenvatting productietaak ${ repackOrder.id ?? '' }`) }
            { getH3(product?.name ?? summarySkeleton) }
            { isInline && getH4(datetimeToLocale(repackOrder?.repack_date, 'long')) }
            { getH3(`Productopmerking:`) }
            { getH4( product?.comments ) }

            { repackStatus === 'booked' && <Alert type={ 'info' } message={ 'Productietaak is ingeboekt.' } className={ 'my-4' }/> }
            { repackStatus === 'backorder' && !repackOrder.parentCharge && <Alert type={ 'warning' } message={ 'Productietaak is een backorder.' } className={ 'my-4' }/> }

            {repackOrder.repacked_by && <div className={'mt-4'}>
                { getH3('Uitgevuld door:') }
                {getH4( repackOrder.repacked_by )}
            </div>}

            {![0, undefined].includes(repackOrder.repack_time_in_minutes) && <div className={'mt-4'}>
                { getH3('Uitvultijd:') }
                {getH4( `${repackOrder.repack_time_in_minutes} minuten` )}
            </div>}

            { !isInline && !isBookIn && <>

                <div className={ 'mt-4 grid grid-cols-3 gap-4' }>
                    <div>
                        { getH4('Uitvuldatum') }
                        <Input
                            label={ '' }
                            name={ '' }
                            value={ sqlDateTimeToDate(repack_date) }
                            type={ 'date' }
                            disabled={ repackStatus === 'booked' }
                            onChange={ async(value) => {
                                await repackHttp.update(repackOrder.id, {
                                    repack_date: `${ value }`
                                });
                            } }
                        />
                    </div>


                    <div className={ 'col-span-2 grid grid-cols-2 gap-4' }>

                        <div className="">
                            { ([ 'backorder', 'pending', 'processing', 'processed' ] as RepackingStatus[]).includes(repackStatus ?? 'backorder') && <>
                                { getH4('Status') }
                                <Select
                                    name={ '' }
                                    disabled={ repackStatus === 'backorder' }
                                    onChange={ async(option) => {
                                        const newStatus = option.value as RepackingStatus;
                                        setRepackStatus(newStatus);
                                        await repackHttp.update(repackOrder.id, {
                                            status: newStatus
                                        });
                                    } }
                                    options={ ([ 'backorder', 'pending', 'processing', 'processed' ] as RepackingStatus[]).filter(status => repackStatus === 'backorder' ?status === 'backorder' :status !== 'backorder').map((status, i) => ({
                                        displayName: RepackStatusTranslation(status),
                                        value: status,
                                        selected: repackStatus ?repackStatus === status :i === 0
                                    })) }/>
                            </> }


                        </div>

                        <div className={ 'relative' }>
                            { repackStatus === 'processed' && <ActionButton icon={ '' } className={ '!py-1.5 absolute bottom-2 !text-center !w-full !px-0' } text={ 'Inboeken' } onClick={ bookInAction }/> }
                            { repackStatus === 'backorder' && <ActionButton icon={ '' } className={ '!py-1.5 absolute bottom-2 !text-center !w-full !px-0' } text={ 'Bron charge toevoegen' } onClick={ editAction }/> }
                            { ([ 'pending', 'processing' ] as RepackingStatus[]).includes(repackStatus ?? 'backorder') && <EditButton text={ 'Productietaak bewerken' } className={ '!py-1.5 absolute bottom-2 !text-center !w-full !px-0' } onClick={ editAction }/> }

                        </div>
                    </div>

                </div>
                { getHr() }
            </> }
        </div>

        { allergens.length > 0 && <>
            <div className={ 'rounded-md py-4 my-4 px-6 shadow shadow-aqua/20' }>

                { getH3('Allergenen') }
                <ul className={ 'list-disc list-inside' }>
                    { allergens.map(allergen => <li key={ allergen }>{ getSpan(allergen) }</li>) }
                </ul>
            </div>
        </> }

        { getHr() }

        <div className="grid grid-cols-9 gap-1">
            <div className={ 'col-span-4' }>{ getDisplayItem(parentItem) }</div>
            <div className={ 'col-auto relative' }>
                <span className={ 'text-5xl text-aqua absolute top-[9rem] left-0 right-0 !text-center' }>→</span>
            </div>
            <div className={ 'col-span-4' }>{ getDisplayItem(targetItem) }</div>
        </div>

        { getHr() }

        <div className={ 'relative' }>

        { getH3('Verpakkingsonderdelen benodigd') }
        <div className={ 'grid grid-cols-7 gap-x-8 mt-4' }>
            <div className={ 'all-children:!text-right col-span-1' }>{ getH4('Aantal') }</div>
            <div className="col-span-2">{ getH4('Type') }</div>
            <div className="col-span-4">{ getH4('Naam') }</div>
        </div>

        { (targetArticle?.package?.packageParts ?? []).length>0
          ?<>
              <ul className={ classNames('relative') }>{ (targetArticle?.package?.packageParts ?? []).map((part, i) =>
                  <li key={ i } className={ classNames('py-1.5 border-t-graphite border-t border-opacity-10 all-children:text-graphite') }>
                      <div className={ 'grid grid-cols-7 gap-x-8' }>
                          <div className={ 'all-children:!text-right col-span-1' }>{ getP(<>{ targetArticle?.is_bulk ?1 :targetItem.amount }x</>) }</div>
                          <div className="col-span-2">{ getP(part.part?.type) }</div>
                          <div className="col-span-4">{ getP(part.part?.name) }</div>
                      </div>
                  </li>) }
              </ul>
          </>
          :<ul>
              <li className={ classNames('py-1.5 border-t-graphite border-t border-opacity-10 all-children:text-graphite') }>
                  <div className={ 'grid grid-cols-7 gap-x-8' }>
                      <div className={ 'all-children:!text-right col-span-1' }>{ getP(<>{ targetArticle?.is_bulk ?1 :targetItem.amount }x</>) }</div>
                      <div className="col-span-2">{ getP('Verpakking') }</div>
                      <div className="col-span-4">{ getP(targetArticle?.package?.name ?? 'Geen verpakking beschikbaar!') }</div>
                  </div>
              </li>
          </ul> }
          </div>


        { !isInline && <div className={ 'mt-6' }>
            <div className={ 'pb-8' }>
                { getH3('Opmerkingen') }
                <TextArea label={ '' } maxLength={ 256 } disabled={ repackStatus == 'booked' } name={ 'comments' } value={ repackOrder.comments } onChange={ async(value) => {
                    await repackHttp.update(repackOrder.id, {
                        comments: `${ value }`
                    });
                } }/>

                <SaveButton className={ 'float-right mt-2' } onClick={ onClose }/>

            </div>
        </div> }
    </div>;
};
export default RepackingReceipt;