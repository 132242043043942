import React, { useEffect, useState } from 'react';
import { sqlDateTimeToDate } from './lib/samfe/modules/Parse/Date';
import Router from './lib/samfe/modules/Router/Router';
import useHealth from './resources/health/useHealth';
import ApplicationTemplate from './templates/layout/ApplicationTemplate';

const App: React.FC = (): JSX.Element => {

    const health = useHealth();
    const [ currentVersionDate, setCurrentVersionDate ] = useState('');
    useEffect(() => {
        health.getItem(undefined, {}, true).then(res => setCurrentVersionDate(sqlDateTimeToDate(res?.VersionDate)));
    }, []);
    return <>
        <ApplicationTemplate>
            <Router/>
        </ApplicationTemplate>
        <small className={ 'version font-semibold text-graphite text-xs fixed z-10 !backdrop-blur bg-milk bg-opacity-10 rounded-md pl-2.5 pr-3.5 drop-shadow py-1 bottom-0 right-0' }>V{ currentVersionDate }</small>
    </>;
};

export default App;
