import React, { FC, ReactNode, useMemo, useState } from 'react';
import Disclosure from '../../lib/samfe/components/Disclosure/Disclosure';
import ExampleGraph from '../../lib/samfe/components/Graph/ExampleGraph';
import Stats from '../../lib/samfe/components/Stats';
import useToaster from '../../lib/samfe/components/Toaster/useToaster';
import { datetimeToLocale } from '../../lib/samfe/modules/Parse/Date';
import { toEuroString } from '../../lib/samfe/modules/Parse/Number';
import { SortObjectArrayByDate } from '../../lib/samfe/modules/Parse/Object';
import useId from '../../lib/samfe/modules/Router/useId';
import ShowPage from '../../templates/pages/ShowPage';
import PdfFile from '../document/PdfFile';
import CustomerForm from './CustomerForm';
import { CustomerModel } from './CustomerTypes';
import CustomerProductTable from './pivot/CustomerArticle/CustomerArticleTable';
import CustomerArticleLabelForm from './pivot/CustomerArticleLabel/CustomerArticleLabelForm';
import CustomerArticleLabelTable from './pivot/CustomerArticleLabel/CustomerArticleLabelTable';
import CustomerChargeTable from './pivot/CustomerCharge/CustomerChargeTable';
import CustomerSaleTable from './pivot/CustomerSaleTable';
import useCustomer from './useCustomer';


const ContactListItem: FC<{ title: string, children: ReactNode }> = ({ title, children }) => (
    <div className={ 'mx-4 sm:mx-10 2xl:mx-12' }>
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-sm font-medium text-gray-500">{ title }</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                { children }
            </dd>
        </div>
    </div>
);

const CustomerShow: FC = (): JSX.Element => {

    const id = useId();
    const { setToasterProps } = useToaster();

    const [ currentCustomer, setCurrentCustomer ] = useState<CustomerModel>();

    const title = useMemo(() => {
        return currentCustomer?.company + ' (' + currentCustomer?.number + ')' ?? `${ currentCustomer?.first_name } ${ currentCustomer?.last_name }`;
    }, [ currentCustomer?.company, currentCustomer?.first_name, currentCustomer?.last_name ]);

    return (
        <ShowPage
            title={ title }
            breadcrumb={ title }
            setCurrentModel={ setCurrentCustomer }
            relations={ [ 'spent' ] }
            counts={ [ 'sales' ] }
            FormModal={ CustomerForm }
            httpHook={ useCustomer }
            formActions={ [
                {
                    name: 'Klantlabel',
                    tabId: 'customer-article-labels',
                    icon: 'add',
                    form: CustomerArticleLabelForm
                }
            ] }

            customActions={ [
                {
                    name: 'API sleutel kopieeren',
                    callback: () => {
                        if (!currentCustomer) {
                            return;
                        }
                        navigator.clipboard.writeText(currentCustomer.api_key).then(() => {
                            setToasterProps({
                                title: 'API key gekopieerd',
                                show: true
                            });
                        });
                    }
                }
            ] }

            tabs={ [
                {
                    id: 'overview',
                    name: 'Overzicht',
                    element: <>
                        <Stats stats={ [
                            {
                                name: 'Klantnummer',
                                value: currentCustomer?.number ?? 0
                            },
                            {
                                name: 'Aantal bestellingen',
                                value: currentCustomer?.sales_count ?? 0
                            },
                            {
                                name: 'Totaal besteed',
                                value: <>
                                    <span>{ toEuroString(currentCustomer?.spent?.total) }</span>
                                    <small className={ 'pt-2 block' }>incl. { toEuroString(currentCustomer?.spent?.discount) } korting</small>
                                </>
                            },
                            {
                                name: 'Laatste bestelling',
                                value: datetimeToLocale( currentCustomer?.spent?.last_ordered_at )
                            },
                            {
                                name: 'Maakt eigen label',
                                value: currentCustomer?.has_article_label ? 'Nee' : 'Ja'
                            },
                            {
                                name: 'Geen label',
                                value: currentCustomer?.has_label ? 'Ja' : 'Nee'
                            },
                            {
                                name: '1 product per doos',
                                value: currentCustomer?.one_product_per_box ?'Ja' :'Nee'
                            },
                            {
                                name: 'Klantlabel op de doos',
                                value: currentCustomer?.clientlabel_on_box ?'Ja' :'Nee'
                            },
                            {
                                name: 'Aantallen sticker op doos',
                                value: currentCustomer?.sticker_amount_on_box ?'Ja' :'Nee'
                            }
                        ] }/>

                        <Disclosure defaultOpen={ true } title={ 'Klantgegevens' }>
                            <ContactListItem title={ 'Contact' }>
                                <span className={ 'font-medium' }>{ currentCustomer?.first_name } { currentCustomer?.last_name }</span><br/>
                                <a href={ `mailto:${ currentCustomer?.email }` }>{ currentCustomer?.email }</a><br/>
                                <a href={ `tel:${ currentCustomer?.phone }` }>{ currentCustomer?.phone }</a><br/>
                            </ContactListItem>

                            <ContactListItem title={ 'Adres' }>
                                <span>{ currentCustomer?.shipping_address_1 } { currentCustomer?.shipping_address_2 }</span><br/>
                                <span>{ currentCustomer?.shipping_postcode } { currentCustomer?.shipping_city }</span><br/>
                                <span>{ currentCustomer?.shipping_country }</span>
                            </ContactListItem>

                            { currentCustomer?.comments && <ContactListItem title={ 'Notities' }>
                                { currentCustomer.comments }
                            </ContactListItem> }
                        </Disclosure>

                        <ExampleGraph/>
                    </>
                },
                {
                    id: 'customer-article-labels',
                    name: 'Labels',
                    element: id ? <CustomerArticleLabelTable customerId={ id }/> : <></>
                },
                {
                    id: 'sales',
                    name: 'Bestellingen',
                    element: <CustomerSaleTable parentId={ id }/>
                },
                {
                    id: 'products',
                    name: 'Producten',
                    element: <CustomerProductTable parentId={ id }/>
                },
                {
                    id: 'charges',
                    name: 'Charges',
                    element: <CustomerChargeTable parentId={ id }/>
                },
                {
                    id: 'gira',
                    name: 'Gira',
                    element: <PdfFile id={ id } fileType={ 'gira' } customFileName={ `gira-${ currentCustomer?.number }` }/>
                }
            ] }
        />
    );
};
export default CustomerShow;