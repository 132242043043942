import { SelectOption } from '../../../../lib/samfe/components/Form/Effects/useSelect';
import { round } from '../../../../lib/samfe/modules/Parse/Number';
import { SoftDeleteModel } from '../../../../lib/samfe/types/ModelTypes';
import { ArticleModel } from '../../../article/ArticleTypes';
import { FileModel } from '../../../document/FileTypes';
import { WithCount } from '../../../generic/ModelTypeExtensions';
import { LabelVersionModel } from '../../../labels/LabelVersionTypes';
import { VersionLogModel } from '../../../product/pivot/versionLog/VersionLogTypes';
import { ProductModel } from '../../../product/ProductTypes';
import { CustomerModel } from '../../CustomerTypes';


export type CustomerArticleLabelRelationsBluePrint = 'customer'
    |'article'
    |'product'
    |'productVersion'
    |'labelVersion'
    |'file'

type LabelWidth = 0|108|139|233|306|460.019|372.142|460.016|726.473|933.402
type LabelHeight = 0|50|70|75|108|207.73|207.73|264.425|278.598|372.142

export type UnsafeFormatKey<W extends number, H extends number> = `${ H }x${ W }`
type LabelFormatKey<W extends LabelWidth, H extends LabelHeight> = `${ H }x${ W }`

export type SupportedFormat =
    LabelFormatKey<139, 50>
    |LabelFormatKey<139, 70>
    |LabelFormatKey<233, 75>
    |LabelFormatKey<306, 108>
    |LabelFormatKey<108, 50>
    |LabelFormatKey<0, 0>

type FormatDimensionStruct<W extends number, H extends number> = {
    id: number
    width: W,
    height: H,
    ratio: number
}

export type FormatDimensionsUnsafe = Omit<FormatDimensionStruct<number, number>, 'id'>

export type FormatDimensions = FormatDimensionStruct<LabelWidth, LabelHeight>

type ReferenceFormatDimensions = Omit<FormatDimensions, 'ratio'>
type LabelFormatDefinition = {
    [Key in SupportedFormat]: ReferenceFormatDimensions
};
type LabelFormat = {
    [Key in SupportedFormat]: FormatDimensions
};

export const LabelFormatDefinitions: LabelFormatDefinition = {
    '50x139': {
        id: 1,
        width: 460.019,
        height: 207.73
    },
    '70x139': {
        id: 2,
        width: 460.016,
        height: 264.425
    },
    '75x233': {
        id: 3,
        width: 726.473,
        height: 278.598
    },
    '108x306': {
        id: 4,
        width: 933.402,
        height: 372.142
    },
    '50x108': {
        id: 5,
        width: 372.142,
        height: 207.73
    },
    '0x0': {
        id: 6,
        width: 0,
        height: 0
    }
}


export const LabelFormats: LabelFormat = Object.keys(LabelFormatDefinitions).reduce((acc, key) => {
    const supportedFormat = key as SupportedFormat;

    const entry = LabelFormatDefinitions[supportedFormat];
    let ratio = round(entry.width / entry.height, 2);
    if (Number.isNaN(ratio)) {
        ratio = 1;
    }
    return {
        ...acc,
        [supportedFormat]: {
            ...entry,
            ratio
        }
    };
}, {} as LabelFormat);

type LabelRatio = { [k: number]: SupportedFormat }

export const supportedRatios = Object.keys(LabelFormatDefinitions).reduce((acc, key) => {
    const supportedFormat = key as SupportedFormat;
    const entry = LabelFormats[supportedFormat];
    return {
        ...acc,
        [entry.ratio]: supportedFormat
    };
}, {} as LabelRatio);

export const FindLabelByRatio = (ratio: number): SupportedFormat|undefined => {
    if (Object.hasOwn(supportedRatios, ratio)) {
        return supportedRatios[ratio];
    }
    return undefined;
};


export const LabelFormatOptions = (defaultFormat?: SupportedFormat): SelectOption[] => (
    Object.keys(LabelFormats).map((labelFormat, i) => {

        const format = LabelFormats[labelFormat as SupportedFormat];
        return ({
            displayName: `${ format.id } (${ labelFormat }) [${ format.ratio }]`,
            value: labelFormat,
            selected: defaultFormat ?defaultFormat == labelFormat :i == 0
        });
    })
);

export const GetLabelDimensionsByFormatKey = (format: SupportedFormat): FormatDimensions => (
    LabelFormats[format]
);

export const GetFormatKeyByLabelDimensions = <W extends LabelWidth, H extends LabelHeight>(w: W, h: H): LabelFormatKey<W, H> => (
    `${ h }x${ w }`
);

export const GetFormatIdByLabelDimensions = <W extends LabelWidth, H extends LabelHeight>(w: W, h: H): number => (
    LabelFormatDefinitions[GetFormatKeyByLabelDimensions(w, h) as SupportedFormat]?.id ?? 6
);


export type CustomerArticleLabelFields = {
    product_id: number;
    product_version_id: number;
    label_version_id: number
    article_id: number;
    customer_id: number;
    label_width: LabelWidth
    label_height: LabelHeight
}

export type CustomerArticleLabelDto = Partial<CustomerArticleLabelFields>

export type CustomerArticleLabelRelations = {
    customer: CustomerModel,
    article: ArticleModel,
    product: ProductModel,
    productVersion: VersionLogModel,
    labelVersion: LabelVersionModel,
    file: FileModel,
}

export type CustomerArticleLabelModel =
    SoftDeleteModel
    &CustomerArticleLabelDto
    &Partial<CustomerArticleLabelRelations>
    &WithCount<Partial<CustomerArticleLabelRelations>>&{
    file_id: string|null,
    label_format_id: number
    quantity?: number // injected when called by SaleModel as relation
    sale_id: number|null
    shipped_at: string|null
}
