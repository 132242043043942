import { AxiosResponse } from 'axios';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { DocumentCallback } from 'react-pdf/src/shared/types';
import { ActionButton } from '../../../../lib/samfe/components/Button';
import useAxios from '../../../../lib/samfe/modules/Http/useAxios';
import { classNames } from '../../../../lib/samfe/modules/Parse/String';
import { viewPdf } from '../../../document/pdfFunctions';

export type Props = {
    chargeId?: number,
    batchCode?: string,
    quantityOnSticker?: number,
    restSticker?: number,
    stickerType: 'sticker'|'pallet-sticker'|'small-sticker'
}

const StickerPreview: FC<Props> = ({
    chargeId,
    batchCode,
    quantityOnSticker,
    restSticker,
    stickerType,
}) => {

    const { get } = useAxios();
    const [ abortController, setAbortController ] = useState<AbortController>();

    const [ numPages, setNumPages ] = useState<number>();
    const [ file, setFile ] = useState<File|undefined>(undefined);
    const [ fileBlob, setFileBlob ] = useState<Blob|undefined>(undefined);

    const fileName = useMemo(() => {
        return `${ stickerType }-${ batchCode }-${ quantityOnSticker }stuks.pdf`;
    }, [ stickerType, batchCode, quantityOnSticker ]);

    const fileUrl = useMemo(() => {
        if (!fileBlob) {
            return undefined;
        }
        return window.URL.createObjectURL(fileBlob);
    }, [ fileBlob ]);

    const loadFile = async(): Promise<void> => {

        if (quantityOnSticker === undefined || isNaN(quantityOnSticker)) {
            return;
        }

        if (abortController) {
            abortController.abort();
        }

        const newAbortController = new AbortController();
        setAbortController(newAbortController);
        await get<string>(
            `documents/${ stickerType }/${ chargeId }/${ quantityOnSticker }/1/${ restSticker }`,
            undefined,
            {
                responseType: 'blob',
                signal: newAbortController.signal
            }
        )
            .then((r: AxiosResponse<string, any>) => {
                const blob = new Blob([ r.data ], { type: r.headers['content-type'] });
                const file = new File([ blob ], fileName);
                setAbortController(undefined);
                setFileBlob(blob);
                setFile(file);
            });
    };

    useEffect(() => {
        if (!chargeId) {
            return;
        }
        loadFile().then();
    }, [ chargeId, quantityOnSticker, batchCode, restSticker ]);

    const onDocumentLoadSuccess = ({ numPages }: DocumentCallback): void => {
        setNumPages(numPages);
    };

    const download = () => {
        if (!fileUrl) {
            return;
        }

        if (!fileName) {
            window.open(fileUrl);
            return;
        }

        // create <a> tag dynamically
        const fileLink = window.document.createElement('a');
        fileLink.href = fileUrl;

        // it forces the name of the downloaded file
        fileLink.download = fileName;

        // triggers the click event
        fileLink.click();
    };

    const viewFile = (): void => viewPdf(`${ chargeId }/${ quantityOnSticker }/1/${ restSticker }`, stickerType);

    return (
        <>
            <div className={ 'h-[calc(100vh-13rem)] -mr-12' }>

                <div className={ 'flex gap-x-4 shadow pt-2 pb-3 bg-white' }>
                    <ActionButton
                        text={ 'Download' }
                        icon={ '' }
                        style={ 'tertiary' }
                        onClick={ download }
                        className={ ' !w-auto' }
                    />

                    <ActionButton
                        text={ 'Open in nieuw tab' }
                        icon={ '' }
                        style={ 'tertiary' }
                        onClick={ viewFile }
                        className={ ' !w-auto' }
                    />
                </div>

                <div className={ 'px-6' }>
                    <Document
                        className={ classNames('w-fit mx-auto', stickerType == 'sticker' && file != undefined ? '-rotate-90 -mt-4' : 'pt-12 pb-4') }
                        file={ file }
                        onLoadSuccess={ onDocumentLoadSuccess }
                        loading={ '' }
                        noData={ 'Het bestand is (nog) niet gevonden.' }
                        renderMode={ 'canvas' }
                    >
                        { Array.from(Array(numPages ?? 1).keys()).map(i => <Page
                            key={ i }
                            pageNumber={ i + 1 }
                            className={ ' border border-aqua/20 drop-shadow shadow-aqua/20 shadow-md py-1 mb-2' }
                            scale={ stickerType == 'sticker' ?2 :1 }
                        />) }
                    </Document>
                </div>

            </div>
        </>

    );
};

export default StickerPreview;
