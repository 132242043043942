import React from 'react';
import { classNames } from '../../../../lib/samfe/modules/Parse/String';
import { TableActionInlineButton, TableDestructionInlineButton } from '../../../button';
import { TableActionProps } from '../../types/TableAction';
import { Tooltip } from "flowbite-react";

const TableActionInline = <T extends object>({
    disabled = false,
    onClick,
    children,
    variation = 'primary',
    item,
    className
}: TableActionProps<T>) => {

    switch(children) { 
      case 'Openen': { 
        children = 'open_in_new'; 
         break; 
      } 
      case 'Downloaden': { 
         children = 'download'; 
         break; 
      } 
      case 'Afdrukken': { 
         children = 'print'; 
         break; 
      } 
      case 'Archiveren': { 
         children = 'delete'; 
         break; 
      } 
      case 'Aanpassen': { 
         children = 'edit'; 
         break; 
      } 
      case 'Verzenden': { 
         children = 'send'; 
         break; 
      } 
      case 'Dupliceren': { 
         children = 'file_copy'; 
         break; 
      } 
      case 'Uploaden': { 
         children = 'upload'; 
         break; 
      } 
      case 'Bestellen': { 
         children = 'add_shopping_cart'; 
         break; 
      } 
      case 'De-archiveren': { 
         children = 'unarchive'; 
         break; 
      } 
      default: { 
        //  children = 'live_help ' . children; 
         break; 
      } 
   }

    const numb = 'tooltip-' + Math.floor(Math.random() * (1000 - 1 + 1) + 1);
   
    return (
        <div>
            { variation == 'primary' && <>
              <Tooltip arrow={false} placement='left' content={children}>
                <TableActionInlineButton
                  { ...item }
                  className={ 
                    (disabled ? 'cursor-pointer group-hover:text-aqua-hover opacity-[15%] group-hover:opacity-80 select-none ml-2 mr-3 flex-shrink-0 text-2xl text-aqua material-icons' : 'cursor-pointer group-hover:text-aqua-hover opacity-[55%] group-hover:opacity-80 select-none ml-2 mr-3 flex-shrink-0 text-2xl text-aqua material-icons')
                   }
                  disabled={ disabled }
                  onClick={ async() => {
                      if (!disabled) {
                          await onClick(item);
                      }
                  } }
                  >{ children }
                  </TableActionInlineButton>
                </Tooltip>
               </> }

            { variation == 'destructive' && <>
                <Tooltip arrow={false} placement='left' content={children}>
                <TableDestructionInlineButton
                { ...item }
                disabled={ disabled }
                className={ classNames('w-full', className) }
                onClick={ async() => {
                    if (!disabled) {
                        await onClick(item);
                    }
                } }
                >{ children }</TableDestructionInlineButton>
                </Tooltip>
                </> }

            <div id={numb} role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-xs opacity-0 tooltip dark:bg-gray-700">
              { children }
            <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
        </div>
    );
};

export default TableActionInline;
