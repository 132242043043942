import React, { FC } from 'react';
import { classNames } from '../../lib/samfe/modules/Parse/String';
import { ButtonDisplay, ButtonVariation, FreeFillButtonProps, IntrinsicButtonProps, PredefinedButtonProps } from './Button';


type Props = IntrinsicButtonProps&{
    variation: ButtonVariation,
    display: ButtonDisplay
}


/*
 variation == 'primary' && '!bg-blue-200 !text-blue-800 hover:!bg-blue-300 hover:!text-blue-900 active:!bg-blue-400 active:!text-blue-900',
 variation == 'destructive' && '!bg-red-200 !text-red-800 hover:!bg-red-300 hover:!text-red-900 active:!bg-red-400 active:!text-red-900',
 */
const Button: FC<Props> = ({
    children,
    className,
    disabled,
    variation,
    display,
    ...props
}) => {
    return (
        <button
            { ...props }
            disabled={ disabled }
            className={ classNames('w-fit h-min leading-4 font-medium text-center focus:outline-2 focus:outline-sky outline-offset-2',
                disabled && 'opacity-50 !cursor-not-allowed',

                display == 'regular' && classNames('rounded-md p-2 text-sm',
                    variation == 'primary' && 'bg-aqua hover:bg-aqua-hover active:bg-aqua-active text-blue-100',
                    variation == 'secondary' && 'bg-blue-200 hover:bg-blue-300 active:bg-blue-400 text-blue-800 hover:text-blue-900 active:text-blue-900',
                    variation == 'positive' && 'bg-emerald hover:bg-emerald-hover active:bg-emerald-active text-green-100',
                    variation == 'destructive' && 'bg-fire hover:bg-fire-hover active:bg-fire-active text-red-50'
                ),

                display == 'squeezed' && classNames('rounded-md py-1 px-2.5 text-xs',
                    variation == 'primary' && 'bg-aqua hover:bg-aqua-hover active:bg-aqua-active text-blue-100',
                    variation == 'secondary' && 'bg-blue-200 hover:bg-blue-300 active:bg-blue-400 text-blue-800 hover:text-blue-900 active:text-blue-900',
                    variation == 'positive' && 'bg-emerald hover:bg-emerald-hover active:bg-emerald-active text-green-100',
                    variation == 'destructive' && 'bg-fire hover:bg-fire-hover active:bg-fire-active text-red-50'
                ),

                display == 'outlined' && classNames('border-2 rounded-md px-2 py-1.5 text-sm',
                    variation == 'primary' && 'border-aqua hover:border-aqua-hover active:border-aqua-active text-aqua hover:text-aqua-hover active:text-aqua-active',
                    variation == 'secondary' && 'border-blue-800 hover:border-blue-900 active:border-blue-900 text-blue-800 hover:text-blue-900 active:text-blue-900',
                    variation == 'positive' && 'border-emerald hover:border-emerald-hover active:border-emerald-active text-emerald hover:text-emerald-hover active:text-emerald-active',
                    variation == 'destructive' && 'border-fire hover:border-fire-hover active:border-fire-active text-fire hover:text-fire-hover active:text-fire-active'
                ),

                display == 'underlined' && classNames('',
                    variation == 'primary' && 'decoration-aqua hover:decoration-aqua-hover active:decoration-aqua-active text-aqua hover:text-aqua-hover active:text-aqua-active',
                    variation == 'secondary' && 'decoration-blue-800 hover:decoration-blue-900 active:decoration-blue-900 text-blue-800 hover:text-blue-900 active:text-blue-900',
                    variation == 'positive' && 'decoration-emerald hover:decoration-emerald-hover active:decoration-emerald-active text-emerald hover:text-emerald-hover active:text-emerald-active',
                    variation == 'destructive' && 'decoration-fire hover:decoration-fire-hover active:decoration-fire-active text-fire hover:text-fire-hover active:text-fire-active ml-2 mr-3 flex-shrink-0 text-2xl material-icons'
                ),

                className
            ) }
        >
            { children }
        </button>
    );
};


// Pre defined

export const ArchiveButton: FC<PredefinedButtonProps> = (props) => (
    <Button{ ...props } display={ 'regular' } variation={ 'destructive' }>
        Archiveren
    </Button>
);

export const CancelButton: FC<PredefinedButtonProps> = (props) => (
    <Button{ ...props } display={ 'underlined' } variation={ 'primary' }>
        Annuleren
    </Button>
);

export const CreateButton: FC<PredefinedButtonProps> = (props) => (
    <Button{ ...props } display={ 'regular' } variation={ 'positive' }>
        Nieuw
    </Button>
);

export const SaveButton: FC<PredefinedButtonProps> = (props) => (
    <Button{ ...props } display={ 'regular' } variation={ 'primary' }>
        Opslaan
    </Button>
);

export const TableActionButton: FC<FreeFillButtonProps> = (props) => (
    <Button{ ...props } display={ 'squeezed' } variation={ 'secondary'}  />
);


export const TableDestructiveButton: FC<FreeFillButtonProps> = (props) => (
    <Button{ ...props } display={ 'squeezed' } variation={ 'destructive'}  />
);

export const TableActionInlineButton: FC<FreeFillButtonProps> = (props, numb) => (
  <span {...props } aria-hidden="true"></span>
);


export const TableDestructionInlineButton: FC<FreeFillButtonProps> = (props) => (
    <Button{ ...props } display={ 'underlined' } variation={ 'destructive'}  />
);

export const ConfirmButton: FC<PredefinedButtonProps> = (props) => (
    <Button{ ...props } display={ 'regular' } variation={ 'primary' }>
        Bevestigen
    </Button>
);

export const SecondaryCreateButton: FC<PredefinedButtonProps> = (props) => (
    <Button{ ...props } display={ 'outlined' } variation={ 'positive' }>
        Nieuw
    </Button>
);


//  Free fill

export const ActionButton: FC<FreeFillButtonProps> = (props) => (
    <Button{ ...props } display={ 'regular' } variation={ 'primary' }/>
);

export const SecondaryActionButton: FC<FreeFillButtonProps> = (props) => (
    <Button{ ...props } display={ 'outlined' } variation={ 'primary' }/>
);

export const DestructiveButton: FC<FreeFillButtonProps> = (props) => (
    <Button{ ...props } display={ 'regular' } variation={ 'destructive' }/>
);


export default Button;
