import React, { FC, useState } from 'react';
import { useForm } from '../../lib/samfe/components/Form';
import useAsyncInit from '../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../lib/samfe/components/Form/Effects/useSchema';
import { optionIsSelected } from '../../lib/samfe/components/Form/Support/FieldSupport';
import Yup from '../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../lib/samfe/components/Modal/FormModal/FormModal';
import { getPackageName } from '../package/PackageFunctions';
import { PackageModel } from '../package/PackageTypes';
import usePackage from '../package/usePackage';
import { formatProductNumber } from '../product/ProductFunctions';
import { ProductModel } from '../product/ProductTypes';
import useProduct from '../product/useProduct';
import { parseArticleDisplayNumber } from './ArticleFunctions';
import { ArticleDto, ArticleFields, ArticleModel, ArticleRelationsBluePrint, ArticleUnits, NormalizeArticleUnit } from './ArticleTypes';
import useArticle from './useArticle';


const ArticleForm: FC<ExtendFormModalProps<ArticleDto>> = ({ id, open, setOpen, onSuccess }): JSX.Element => {

    const article = useArticle();

    const [ currentProduct, setCurrentProduct ] = useState<ProductModel>();
    const [ currentPackage, setCurrentPackage ] = useState<PackageModel>();

    /**
     *
     */
    const shape = (initialArticle: ArticleModel|undefined = undefined): Shape<ArticleDto> => ({

        number: Yup.string()
            .hidden(true)
            .label('Nummer')
            .controlType('input')
            .inputType('hidden'),

        product_id: Yup.number()
            .required()
            //.hidden(id !== undefined)
            // .disabled(currentArticle !== undefined)
            .label('Product')
            .controlType('selectSearch')
            .selectSearchConfig({
                useHook: useProduct,
                onChange: setCurrentProduct,
                expectsInitialModel: false,
                initialModel: initialArticle?.product,
                searchOptions: {
                    searchCols: [ 'productDisplayNumber', 'name' ],
                    relations: [ 'productDisplayNumber' ],
                    valueCol: 'id',
                    limit: 'all',
                    filter: 'archived=0', // @fixme monkey patch for limit all
                    displayName: model => `[${ model.productDisplayNumber }] ${ model.name }`
                }
            }),

        package_id: Yup.number()
            .required()
            //.hidden(id !== undefined)
            // .disabled(currentArticle !== undefined)
            .label('Verpakking')
            .controlType('selectSearch')
            .selectSearchConfig({
                useHook: usePackage,
                expectsInitialModel: id !== undefined,
                initialModel: initialArticle?.package,
                onChange: (packageModel) => {
                    setCurrentPackage(packageModel);
                },
                searchOptions: {
                    searchCols: [ 'name' ],
                    valueCol: 'id',
                    limit: 'all',
                    relations: [ 'packageParts.part' ],
                    filter: 'archived=0', // @fixme monkey patch for limit all
                    displayName: model => `[${ formatProductNumber(model.number) }] ${ getPackageName(model) }`
                }
            }),

        amount: Yup.number()
            .required()
            // .hidden(initialArticle !== undefined)
            // .disabled(currentArticle !== undefined)
            .label('Inhoud')
            .controlType('input')
            .description('vb: 60 capsules, bij bulk vul je 1 in en geef hieronder aan dat het bulk is')
            .inputType('number'),

        is_bulk: Yup.boolean()
            .label('Is bulk?')
            // .hidden(initialArticle !== undefined)
            .controlType('switch'),
        unit: Yup.string()
            .required()
            // .hidden(initialArticle !== undefined)
            // .disabled(currentArticle !== undefined)
            .label('Eenheid')
            .controlType('select')
            .options(ArticleUnits.map((unit, i) => ({
                displayName: unit,
                value: unit,
                selected: optionIsSelected(NormalizeArticleUnit(initialArticle?.unit), unit, i)
            }))),

        minimum_stock: Yup.number()
            .required()
            .label('Minimum voorraad')
            .controlType('input')
            .inputType('number')
            .steps(1)
            .min(0),


        delivery_time: Yup.number()
            .required()
            .label('Levertijd in weken')
            .controlType('input')
            .inputType('number')
            .steps(1)
            .min(0),

        delivery_margin: Yup.number()
            .label('Afwijking levertijd in procenten')
            .controlType('input')
            .inputType('number')
            .steps(0.1)
            .min(0),

        // @todo what is exclude till, exclude in days or weeks?
        exclude_till: Yup.string()
            .label('exclude_till')
            .hidden(true)
            .controlType('input')
            .inputType('hidden'),

        comments: Yup.string()
            .label('Artikel notitie')
            .controlType('textArea')
            .inputType('text'),

        active: Yup.boolean()
            .label('Artikel is actief')
            .controlType('checkbox')
            .defaultValue(initialArticle ?(initialArticle?.active === true || initialArticle?.active === 1) :true),

        exclude_to_order: Yup.boolean()
            .label('Artikel NIET meenemen als voorraad indicator in Inkooplijst')
            .controlType('checkbox')
            .defaultValue(initialArticle ?(initialArticle?.exclude_to_order === true) :false)
    });


    /**
     *
     */
    const { validationSchema, setShape } = useSchema<ArticleDto>(shape());

    const initializer = async() => {
        const currentArticle = await article.getItem(id, { with: [ 'package.packageParts.part', 'product.productDisplayNumber' ] }).then((article) => {
            setCurrentProduct(article?.product);
            setCurrentPackage(article?.package);
            return article;
        });
        setShape(shape(currentArticle));
    };

    /**
     *
     */
    const { formikConfig, formFields } = useForm<ArticleModel, ArticleDto, ArticleRelationsBluePrint>({
        id,
        validationSchema,
        onSuccess,
        useHttpHook: useArticle,
        initializer,
        initialized: useAsyncInit(initializer, open),
        morphPayload: (formikValues, currentDto, initialValues) => {

            const payload = {
                ...currentDto,
                active: [ 1, '1', true, 'true' ].includes(currentDto.active ?? formikValues.active) ?1 :0
            };

            payload['number'] = parseArticleDisplayNumber(
                currentProduct?.number,
                currentDto.amount ?? initialValues?.amount,
                currentPackage?.number ?? 0
            );

            return payload as Partial<ArticleFields>;
        }
    });


    return <FormModal
        id={ id }
        resource={ 'Artikel' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
    />;
};

export default ArticleForm;
