import React from 'react';

type DateBlockWithListProps = {
  date: string;       // Formaat: '27-03'
  year: string;       // Formaat: '2025'
  items: string[];    // Lijst met opsommingsitems
};

const DateBlockWithList: React.FC<DateBlockWithListProps> = ({ date, year, items }) => {
  return (
    <div className="flex items-start gap-4 bg-white p-4 rounded shadow w-full">
      <div className="bg-blue-100 text-blue-800 px-4 py-2 rounded text-center leading-tight min-w-[100px] whitespace-nowrap">
        <div className="text-lg font-bold">{date}</div>
        <div className="text-sm">{year}</div>
      </div>
      <ul className="list-disc list-outside pl-5 text-sm text-gray-700">
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default DateBlockWithList;