import React, { FC, useMemo } from 'react';
import Table from '../../components/table';
import { TableColumns } from '../../components/table/types/TableRow';
import LabelVersionForm from './LabelVersionForm';
import { LabelVersionModel, LabelVersionRelationsBluePrint } from './LabelVersionTypes';
import useLabelVersion from './useLabelVersion';


type Props = {
    product_number: string
}

const LabelVersionTable: FC<Props> = ({ product_number }) => {

    const httpHook = useLabelVersion();

    const rows = useMemo((): TableColumns<LabelVersionModel, LabelVersionRelationsBluePrint>[] => [
        {
            header: {
                children: 'Labelversie'
            },
            column: (item) => ({
                children: item.version
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Notitie'
            },
            column: (item) => ({
                children: item.comments
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Aanmaakdatum'
            },
            column: (item) => ({
                children: item.created_at
            }),
            type: 'date'
        },
        {
            header: {
                children: 'Bewerkdatum'
            },
            column: (item) => ({
                children: item.updated_at
            }),
            type: 'date'
        }
    ], []);

    return (
        <Table
            id={ 'label-versions' }
            rows={ rows }
            http={ {
                hook: httpHook,
                sortCol: 'version',
                sortDir: 'DESC',
                filter: {
                    column: 'product_number',
                    operator: '=',
                    value: product_number
                }
            } }
            forms={ {
                edit: {
                    Form: LabelVersionForm,
                    id: (record) => record.id
                }
            } }
            styling={{
              variation: 'inline'
            }}
        />
    );
};

export default LabelVersionTable;