import useHttp from '../../../../../lib/samfe/modules/Http/useHttp';
import { FileDto } from '../../../../document/FileTypes';
import useFile from '../../../../document/useFile';
import { AssessmentBookItem, AssessmentBookPayload, AssessmentBookResponse, AssessmentFileIdentifier } from '../../../types/AssessmentTypes';


const useBookAssessment = () => {

    const fileHook = useFile();

    const bookAssessmentsHook = useHttp<AssessmentBookResponse, AssessmentBookPayload, ''>({
        endpoint: 'assessments/book'
    });

    return async(chargeId: number, assessmentFileItems: AssessmentFileIdentifier[]): Promise<AssessmentBookResponse> => {

        const assessmentBookItems: AssessmentBookItem[] = [];
        for (const { file, assessmentIds } of assessmentFileItems) {
            const nameArr = file.name.split('.');
            const extension = nameArr.pop();
            const name = nameArr.join('.');

            const payload: FileDto = {
                model_id: chargeId,
                name,
                extension,
                file,
                user_id: 0,
                org_id: 1,
                model_name: 'ChargeModel',
                type: 'Analysecertificaat'
            };

            const formData = new FormData();
            Object.keys(payload).forEach(key => {
                formData.append(
                    key,
                    (payload as { [k: string]: any })[key]
                );
            });

            await fileHook
                .create(formData as FileDto)
                .then(async(res) => {

                    const fileId = res?.id;
                    if (!fileId) {
                        console.error(`Could not get id for file`);
                        return;
                    }
                    assessmentBookItems.push({
                        file_id: fileId,
                        assessment_ids: assessmentIds
                    });
                });
        }

        return await bookAssessmentsHook.create({
            items: assessmentBookItems
        }) as unknown as Promise<AssessmentBookResponse>;
    };
};

export default useBookAssessment;
