import React, { FC, useMemo } from 'react';
import AttributeForm from './AttributeForm';
import { AttributeModel, AttributeRelationsBluePrint } from './AttributeTypes';
import useAttribute from './useAttribute';
import {ExtendTableProps} from "../../components/table/types/Table";
import Table from "../../components/table";
import {TableColumns} from "../../components/table/types/TableRow";


type Props = Required<ExtendTableProps>

const AttributeTable: FC<Props> = () => {

    const httpHook = useAttribute();

    const rows = useMemo((): TableColumns<AttributeModel, AttributeRelationsBluePrint>[] => [
        {
            header: {
                children: 'Producteigenschap',
                sortCol: 'name'
            },
            column: (item) => ({
                children: item.name
            }),
            type: 'text',
        },
        {
            header: {
                children: 'Omschrijving',
                sortCol: 'description'
            },
            column: (item) => ({
                children: item.description
            }),
            type: 'text',
        },
        {
            header: {
                children: 'Categorie',
            },
            column: (item) => ({
                children: item.productType?.name
            }),
            type: 'text',
        },
        {
            header: {
                children: 'Veldtype',
                sortCol: 'type'
            },
            column: (item) => ({
                children: item.type
            }),
            type: 'text',
        },   
    ], []);

    return (<>
        <Table
            id={ 'attributes' }
            rows={ rows }
            rowActions={{
                linkTo: (item) => `${item.id}`
            }}
            http={ {
                hook: httpHook,
                searchCols: ['name'],
                sortCol: 'order',
                with: [ 'productType'],
                filterConfig: {
                    hideArchived: false
                },
                disableDeArchiving: false
            } }
            rowSort={{
                orderColumn: 'order',
                idColumn: 'id'
            }}
            styling={ {
                variation: 'inline'
            } }
            forms={ {
                edit: {
                    Form: AttributeForm,
                    id: (record) => record.id,
                },
                // archive: {
                //     id: (record) => record.id,
                //     itemName: (record) => `${ record.name }`,
                //     resourceName: () => 'Producteigenschap'
                // },
            } }
        />
    </>
    );
};

export default AttributeTable;