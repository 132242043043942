import React, { FC, useMemo } from 'react';
import Table from '../../../../components/table';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import { TableColumns } from '../../../../components/table/types/TableRow';
import { PackagePartPivotModel, PackagePartPivotRelationsBluePrint } from './PackagePartPivotTypes';
import usePackagePartPivot from './usePackagePartPivot';


type Props = ExtendTableProps

const PackagePartTable: FC<Props> = ({ parentId }) => {

    const httpHook = usePackagePartPivot(parentId);

    const rows = useMemo((): TableColumns<PackagePartPivotModel, PackagePartPivotRelationsBluePrint>[] => [
        {
            header: {
                children: 'Onderdeel'
            },
            column: (item) => ({
                children: item.part?.name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Korte naam'
            },
            column: (item) => ({
                children: item.part?.short_name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Type'
            },
            column: (item) => ({
                children: item.part?.type
            }),
            type: 'text'
        }
    ], []);

    return (<>
            <Table
                id={ 'package-part-pivot' }
                rows={ rows }
                rowActions={ {
                    linkTo: (item) => `/package-parts/${ item.part_id }`
                } }
                http={ {
                    hook: httpHook,
                    searchCols: [ 'part.name' ],
                    with: [ 'part', 'package' ],
                    sortCol: 'order',
                    sortDir: 'ASC',
                } }
                rowSort={ {
                    idColumn: 'id',
                    orderColumn: 'order'
            } }
                forms={ {
                    archive: {
                        id: (record) => record.id,
                        itemName: (record) => `${ record.part?.name }`,
                        resourceName: () => 'Verpakkingsonderdeel'
                    }
                } }
                styling={{
                  variation: 'inline'
                }}
            />
        </>
    );
};

export default PackagePartTable;
