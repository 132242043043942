import React, { FC, useMemo } from 'react';
import Table from '../../../../components/table';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import { TableColumns } from '../../../../components/table/types/TableRow';
import VersionLogForm from './VersionLogForm';
import { ParsedVersionLogModel, useProductVersionLogByProductId, VersionLogRelationsBluePrint } from './VersionLogTypes';


type Props = ExtendTableProps&{
    productId: number
}

const VersionLogTable: FC<Props> = ({ productId }): JSX.Element => {

    const httpHook = useProductVersionLogByProductId(productId);


    const rows = useMemo((): TableColumns<ParsedVersionLogModel, VersionLogRelationsBluePrint>[] => [
        {
            header: {
                children: 'Productversie'
            },
            column: (item) => ({
                children: item.version
            }),
            type: 'id'
        },
        {
            header: {
                children: 'Notitie (intern)'
            },
            column: (item) => ({
                children: item.internal_comment
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Notitie (extern)'
            },
            column: (item) => ({
                children: item.external_comment
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Aanmaakdatum'
            },
            column: (item) => ({
                children: item.created_at
            }),
            type: 'date'
        },
        {
            header: {
                children: 'Bewerkdatum'
            },
            column: (item) => ({
                children: item.updated_at
            }),
            type: 'date'
        }
    ], []);

    return <>
        <Table
            id={ 'version-log' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `/products/${ item.product_id }?current-tab=version-log`
            } }
            http={ {
                hook: httpHook,
                //with: [ 'product' ],
                sortCol: 'id',
                sortDir: 'DESC',
                //filter: {
                //    column: 'product.number',
                //    operator: '=',
                //    value: productNumber
                //},
                filterConfig: {
                    hideArchived: true
                },
                disableDeArchiving: true
            } }
            forms={ {
                edit: {
                    Form: VersionLogForm,
                    id: (record) => record.id
                }
            } }
            styling={{
              variation: 'inline'
            }}
        />
    </>;
};
export default VersionLogTable;