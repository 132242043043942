import React, { FC, useMemo } from 'react';
import Table from '../../../../../components/table';
import { ExtendTableProps } from '../../../../../components/table/types/Table';
import { TableColumns } from '../../../../../components/table/types/TableRow';
import { thousandSeparator } from '../../../../../lib/samfe/modules/Parse/Number';
import { CompositionProductModel, CompositionProductRelationsBluePrint } from '../CompositionProductTypes';
import ProductExcipientForm from './ProductExcipientForm';
import useCompositionExcipient from './useCompositionExcipient';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ProductExcipientTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useCompositionExcipient(parentId);

    const rows = useMemo((): TableColumns<CompositionProductModel, CompositionProductRelationsBluePrint>[] => [
        {
            header: {
                children: 'Grondstof'
            },
            column: (item) => ({
                children: item.element?.name
            }),
            type: 'text'
        },

        {
            header: {
                children: 'Functie'
            },
            column: (item) => ({
                children: item.element?.excipient?.name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Inhoud per eenheid'
            },
            column: (item) => ({
                children: `${ thousandSeparator(item.amount) } ${ item.unit ?? '[no_unit]' }`
            }),
            type: 'text'
        }
    ], []);

    return <>
        <Table
            id={ 'composition-excipients' }
            rows={ rows }
            rowSort={ {
                idColumn: 'id',
                orderColumn: 'order'
            } }
            http={ {
                hook: httpHook,
                with: [
                    'element.category',
                    'element.excipient',
                    'element.risks'
                ],
                sortCol: 'order',
                sortDir: 'ASC'
            } }
            forms={ {
                edit: {
                    Form: ProductExcipientForm,
                    parentId: (record) => record.product_id,
                    id: (record) => record.id
                },
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => `${ record.element?.name } ${ record.element?.excipient?.name ?`(${record.element?.excipient?.name})` : '' }`,
                    resourceName: () => 'Hulpstof'
                }
            } }
            styling={{
              variation: 'inline'
            }}
        />
    </>;
};

export default ProductExcipientTable;