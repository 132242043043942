import React, {FC, useMemo} from 'react';
import AccountForm from './AccountForm';
import { AccountModel, AccountRelationsBluePrint } from './AccountTypes';
import useAccount from './useAccount';
import {ExtendTableProps} from "../../components/table/types/Table";
import Table from "../../components/table";
import {TableColumns} from "../../components/table/types/TableRow";


type Props = Required<ExtendTableProps>

const AccountTable: FC<Props> = () => {
    const httpHook = useAccount();
    // @todo: implement forget password

    const rows = useMemo((): TableColumns<AccountModel, AccountRelationsBluePrint>[] => [
        {
            header: {
                children: 'Naam',
                sortCol: 'name'
            },
            column: (item) => ({
                children: item.name
            }),
            type: 'text',
        },
        {
            header: {
                children: 'Email',
                sortCol: 'email'
            },
            column: (item) => ({
                children: item.email
            }),
            type: 'mail',
        },
    ], []);

    return (<>
        <Table
            id={ 'accounts' }
            rows={ rows }
            http={ {
                hook: httpHook,
                searchCols: ['name', 'email'],
                sortCol: 'name',
                sortDir: 'ASC',
            } }
            forms={ {
                edit: {
                    Form: AccountForm,
                    id: (record) => record.id,
                },
                // archive: {
                //     id: (record) => record.id,
                //     itemName: (record) => `${ record.name }`,
                //     resourceName: () => 'Account'
                // },
            } }
            styling={{
              variation: 'inline'
            }}
        />
    </>
    );
};

export default AccountTable;
