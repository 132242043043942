import React, { FC, useMemo, useState } from 'react';
import { ActionButton } from '../../../../components/button';
import { API_URL } from '../../../../config/config';
import Input from '../../../../lib/samfe/components/Form/Generic/Input';
import Select from '../../../../lib/samfe/components/Form/Generic/Select/Select';
import { SelectOption } from '../../../../lib/samfe/components/Form/Generic/Select/useSelect';
import useAsyncMemo from '../../../../lib/samfe/hooks/useAsyncMemo';
import useAxios from '../../../../lib/samfe/modules/Http/useAxios';
import { PrinterModel } from '../../../printer/PrinterTypes';
import usePrinterFile from '../../../printer/usePrinterFile';
import StickerPreview from './StickerPreview';


type Props = {
    chargeId?: number,
    batchCode?: string
}
const ChargePalletStickerTab: FC<Props> = ({ chargeId, batchCode }) => {

    const printFile = usePrinterFile();
    const axiosHook = useAxios();

    const [ quantityOnSticker, setQuantityOnSticker ] = useState(0);
    const [ numberOfStickers, setNumberOfStickers ] = useState(1);
    const [ selectedPrinter, setSelectedPrinter ] = useState<PrinterModel>();

    const printers = useAsyncMemo(async() => {
        return await axiosHook.get<{ items: PrinterModel[] }>('printer-jobs/filter/escpos')
            .then(r => {
                if (r.data.items.length>0) {
                    setSelectedPrinter(r.data.items[0]);
                }
                return r.data.items;
            });
    }, [], []);

    const handlePrint = async() => {
        const payload = {
            printerName: selectedPrinter?.name,
            details: `${ API_URL }/api/v1/documents/pallet-sticker/${ chargeId }/${ quantityOnSticker }/${ numberOfStickers }/0`,
            jobName: 'EscPosPrint'
        };
        await printFile.create(payload);
    };


    const printerOptions: SelectOption[] = useMemo(() => {
        if (printers.length == 0) {
            return [
                {
                    displayName: 'Geen printers beschikbaar',
                    value: -1,
                    selected: true
                }
            ];
        }
        return printers.map((printer, i) => ({
            displayName: printer.display_name,
            value: printer.id,
            selected: i == 0
        }) as SelectOption);
    }, [ printers ]);


    return (
        <div className={ 'flex divide-x' }>
            <div className="w-[24rem] min-w-[24rem] pt-4 pr-8">
                <Input
                    label={ 'Aantal artikelen op pallet' }
                    name={ '' }
                    type={ 'number' }
                    value={ quantityOnSticker }
                    onChange={ v => setQuantityOnSticker(
                        parseInt(
                            Math.floor(parseFloat(`${ v ?? 0 }`)).toFixed(0)
                        )
                    ) }
                />

                <Input
                    label={ 'Aantal stickers printen' }
                    name={ '' }
                    type={ 'number' }
                    value={ numberOfStickers }
                    onChange={ v => setNumberOfStickers(
                        parseInt(
                            Math.floor(parseFloat(`${ v ?? 0 }`)).toFixed(0)
                        )
                    ) }
                />

                <Select
                    label={ 'Printer' }
                    name={ '' }
                    onChange={ (option) => {
                        setSelectedPrinter(printers.find(item => item.id == option.value));
                    } }
                    options={ printerOptions }
                />

                <div className={ 'mt-6' }>
                    <ActionButton disabled={ !selectedPrinter } onClick={ handlePrint }>
                        Afdrukken
                    </ActionButton>
                </div>
            </div>
            <div className={ 'w-full' }>
                <StickerPreview
                    chargeId={ chargeId }
                    batchCode={ batchCode }
                    stickerType={ 'pallet-sticker' }
                    quantityOnSticker={ quantityOnSticker }
                    restSticker={0}
                />
            </div>
        </div>
    );
};
export default ChargePalletStickerTab;
