// No inspection caused by non-existing word "Klantlabel"
//noinspection SpellCheckingInspection

import React, { FC, useEffect, useMemo, useState } from 'react';
import useAsyncInit from '../../../../lib/samfe/components/Form/Effects/useAsyncInit';
import useForm from '../../../../lib/samfe/components/Form/Effects/useForm';
import useSchema, { Shape } from '../../../../lib/samfe/components/Form/Effects/useSchema';
import { SelectOption } from '../../../../lib/samfe/components/Form/Effects/useSelect';
import Select from '../../../../lib/samfe/components/Form/Generic/Select/Select';
import Yup from '../../../../lib/samfe/components/Form/Yup';
import { DeleteIcon } from '../../../../lib/samfe/components/Icon';
import { FormModal } from '../../../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../../../lib/samfe/components/Modal/FormModal/FormModal';
import useAsyncMemo from '../../../../lib/samfe/hooks/useAsyncMemo';
import { WhereInHttpParam } from '../../../../lib/samfe/modules/Http/useAxios';
import { ArticleModel } from '../../../article/ArticleTypes';
import useArticle from '../../../article/useArticle';
import DragAndDropField from '../../../document/DragAndDropField';
import { FileDto } from '../../../document/FileTypes';
import useFile from '../../../document/useFile';
import useFileHandler from '../../../document/useFileHandler';
import { LabelVersionModel } from '../../../labels/LabelVersionTypes';
import useLabelVersion from '../../../labels/useLabelVersion';
import useProductVersionLog, { ParsedVersionLogModel } from '../../../product/pivot/versionLog/VersionLogTypes';
import { formatProductNumber } from '../../../product/ProductFunctions';
import { ProductModel } from '../../../product/ProductTypes';
import useProduct from '../../../product/useProduct';
import { CustomerArticleLabelDto, CustomerArticleLabelFields, CustomerArticleLabelModel, CustomerArticleLabelRelationsBluePrint, FindLabelByRatio, FormatDimensions, FormatDimensionsUnsafe, GetFormatKeyByLabelDimensions, GetLabelDimensionsByFormatKey, LabelFormatOptions, SupportedFormat } from './CustomerArticleLabelTypes';
import { getFileDimensions } from './CustomerLabelCalculations';
import useCustomerArticleLabel from './useCustomerArticleLabel';


type FieldShape = Omit<CustomerArticleLabelDto, 'customer_id'|'label_width'|'label_height'>&{
    label_format: SupportedFormat
}

const CustomerArticleLabelForm: FC<ExtendFormModalProps<CustomerArticleLabelDto>> = ({
    parentId, // Customer id
    id, // Customer Article Label id
    open,
    setOpen,
    onSuccess // After file upload
}): JSX.Element => {

    const customerArticleLabelHook = useCustomerArticleLabel();
    const productVersionHook = useProductVersionLog();
    const labelVersionHook = useLabelVersion();
    const articleHook = useArticle();
    const fileHook = useFile();
    const { getFile } = useFileHandler();


    const [ currentProduct, setCurrentProduct ] = useState<ProductModel>();

    const fallbackOptions = useMemo((): SelectOption[] => [
        {
            displayName: 'Geen resultaten beschikbaar',
            value: undefined,
            selected: true,
            disabled: true
        }
    ], []);

    const lookupItems = useAsyncMemo(async() => {
        if (id != undefined) {
            return undefined;
        }

        const cals = await customerArticleLabelHook.getList({
            filter: `customer_id=${ parentId }`,
            with: [ 'product' ],
            select: [ 'id', 'customer_id', 'product_id', 'article_id', 'label_version_id', 'product.id', 'product.number' ]
        });


        type LookupItems = {
            [productNumber: string]: {
                [productId: number]: {
                    [labelVersionId: number]: {
                        articleIds: number[]
                    }
                }
            }
        }

        const lookupItems: LookupItems = {};
        for (const cal of cals) {
            const productNumber = cal.product!.number!;
            const productId = cal.product_id!;
            const labelVersionId = cal.label_version_id!;
            const articleId = cal.article_id!;
            lookupItems[productNumber] = {
                ...lookupItems[productNumber],
                [productId]: {
                    ...lookupItems[productNumber]?.[productId],
                    [labelVersionId]: {
                        ...lookupItems[productNumber]?.[productId]?.[labelVersionId],
                        articleIds: [
                            ...(lookupItems[productNumber]?.[productId]?.[labelVersionId]?.articleIds ?? []),
                            articleId
                        ]
                    }
                }
            };
        }

        return lookupItems;
    }, [ id ], undefined);


    /**
     * Product versions
     */
    const [ currentProductVersion, setCurrentProductVersion ] = useState<ParsedVersionLogModel>();

    const availableProductVersions = useAsyncMemo(async(): Promise<ParsedVersionLogModel[]> => {
        if (!currentProduct?.id) {
            return [];
        }
        return await productVersionHook.getByProductId(currentProduct.id);
    }, [ currentProduct?.id ], []);


    const productVersionOptions = useMemo((): SelectOption[] => {
        if (availableProductVersions.length == 0) {
            return fallbackOptions;
        }
        return availableProductVersions
            .sort((a, b) => a.version<b.version ?1 :-1)
            .map((pv, i) => ({
                displayName: `V${ pv.version }`,
                value: pv.id,
                selected: currentProductVersion ?currentProductVersion.id == pv.id :i === 0
            }));
    }, [ availableProductVersions, currentProductVersion, fallbackOptions ]);

    const handleProductVersionChange = (productVersionId: number) => {
        setCurrentProductVersion(availableProductVersions.find(pv => pv.id == productVersionId));
    };


    /**
     * Label versions
     */
    const [ currentLabelVersion, setCurrentLabelVersion ] = useState<LabelVersionModel>();

    const availableLabelVersions = useAsyncMemo(async(): Promise<LabelVersionModel[]> => {
        if (!currentProduct?.number) {
            return [];
        }
        return await labelVersionHook.getList({ filter: `product_number=${ currentProduct.number }` });
    }, [ currentProduct?.number ], []);

    const labelVersionOptions = useMemo((): SelectOption[] => {
        if (availableLabelVersions.length == 0) {
            return fallbackOptions;
        }
        return availableLabelVersions
            .sort((a, b) => a.version<b.version ?1 :-1)
            .map((lv, i) => ({
                displayName: `V${ lv?.version }`,
                value: lv.id,
                selected: currentLabelVersion ?currentLabelVersion.id == lv.id :i === 0
            }));
    }, [ availableLabelVersions, currentLabelVersion, fallbackOptions ]);

    const handleLabelVersionChange = (labelVersionId: number) => {
        setCurrentLabelVersion(availableLabelVersions.find(lv => lv.id == labelVersionId));
    };


    /**
     * Articles
     */
    const [ currentArticle, setCurrentArticle ] = useState<ArticleModel>();

    const availableArticles = useAsyncMemo(async(): Promise<ArticleModel[]> => {
        if (!currentProduct?.number || !currentProductVersion?.product_id || !currentLabelVersion?.id) {
            return [];
        }
        const productNumber = currentProduct.number;
        const productId = currentProductVersion?.product_id;
        const labelVersionId = currentLabelVersion.id;

        const occupiedArticleIds = lookupItems?.[productNumber]?.[productId]?.[labelVersionId]?.articleIds ?? [];
        let whereIn: WhereInHttpParam|undefined = undefined;
        if (occupiedArticleIds.length>0) {
            whereIn = {
                key: 'id',
                operator: '!=',
                values: occupiedArticleIds
            };
        }
        return await articleHook.getList({
            filter: `product_number=${ currentProduct.number }`,
            whereIn
        });
    }, [ currentProduct?.number, currentProductVersion?.product_id, currentLabelVersion?.id ], []);

    const articleOptions = useMemo((): SelectOption[] => {
        if (availableArticles.length == 0) {
            return fallbackOptions;
        }
        return availableArticles.map((art, i) => ({
            displayName: art.number!,
            value: art.id,
            selected: currentArticle ?currentArticle.id == art.id :i === 0
        }));
    }, [ availableArticles, currentArticle, fallbackOptions ]);

    const handleArticleChange = (articleId: number) => {
        setCurrentArticle(availableArticles.find(art => art.id == articleId));
    };


    /**
     * Label dimensions
     */
    const [ currentLabelFormat, setCurrentLabelFormat ] = useState<SupportedFormat>();
    const [ currentRawFormat, setCurrentRawFormat ] = useState<FormatDimensionsUnsafe>();
    const labelFormatOptions = useMemo((): SelectOption[] => {
        return LabelFormatOptions(currentLabelFormat);
    }, [ currentLabelFormat ]);

    const currentLabelDimensions = useMemo((): FormatDimensions|undefined => {
        if (!currentLabelFormat) {
            return undefined;
        }
        return GetLabelDimensionsByFormatKey(currentLabelFormat);
    }, [ currentLabelFormat ]);


    const handleLabelFormatChange = (format: SupportedFormat) => {
        setCurrentLabelFormat(format);
    };

    const [ formatWarning, setFormatWarning ] = useState(false);
    useEffect(() => {
        if (!currentRawFormat) {
            setCurrentLabelFormat(undefined);
            setFormatWarning(false);
            return;
        }

        const currentRatio = currentRawFormat.ratio;
        const supportedLabel = FindLabelByRatio(currentRatio);
        if (supportedLabel) {
            setCurrentLabelFormat(supportedLabel);
            setFormatWarning(false);
            return;
        }
        setFormatWarning(true);
    }, [ currentRawFormat ]);


    /**
     * Uploads
     */
    const [ droppedFile, setDroppedFile ] = useState<File>();
    const [ initialFileChanged, setInitialFileChanged ] = useState(false);
    const handleDroppedFiles = async(files: File[], initialCall = false) => {
        const file = files.length>0 ?files[0] :undefined;
        if (!initialCall) {
            setInitialFileChanged(true);
        }
        setDroppedFile(file);

        if (!file) {
            return;
        }
        const dimensions = await getFileDimensions(file);
        setCurrentRawFormat(dimensions);
    };

    const handleRemoveFile = () => {
        setDroppedFile(undefined);
        setCurrentRawFormat(undefined);
        setFormatWarning(false);
    };

    const canSave = useMemo(() => {
        return !(
            !parentId
            || !currentProductVersion?.id
            || !currentProductVersion?.product_id
            || !currentLabelVersion?.id
            || !currentArticle?.id
            || !currentLabelDimensions
            || !droppedFile
        );

    }, [
        parentId,
        currentProductVersion?.id,
        currentProductVersion?.product_id,
        currentLabelVersion?.id,
        currentArticle?.id,
        currentLabelDimensions,
        droppedFile
    ]);

    const handleFileSave = async(customerArticleLabelId: number, file: File) => {
        const formData = new FormData();
        const nameArr = file.name.split('.');
        const extension = nameArr.pop();
        const name = nameArr.join('.');

        const payload: FileDto = {
            model_id: customerArticleLabelId,
            name,
            extension,
            file,
            user_id: 0,
            org_id: 1,
            model_name: 'CustomerArticleLabelModel',
            type: 'Klantlabel'
        };

        Object.keys(payload).forEach(key => {
            formData.append(
                key,
                (payload as { [k: string]: any })[key]
            );
        });

        return await fileHook
            .create(formData as FileDto)
            .then(async(res) => {
                return res?.id != undefined;
            }).catch(() => false);
    };

    const yupFieldType = useMemo(() => {
        return id !== undefined ? Yup.string : Yup.number;
    }, [ id ]);

    // No inspection caused by "product_id: yupFieldType()"
    //noinspection TypeScriptValidateJSTypes
    const shape = (): Shape<FieldShape> => ({
        product_id: yupFieldType()
            .label('Product')
            .disabled(id != undefined)
            .controlType(id != undefined ?'input' :'selectSearch')
            .selectSearchConfig({
                initialModel: currentProduct,
                expectsInitialModel: id !== undefined,
                useHook: useProduct,
                onChange: setCurrentProduct,
                searchOptions: {
                    searchCols: [ 'name', 'number' ],
                    limit: 'all',
                    filter: 'archived=0',
                    valueCol: 'id',
                    relations: [ 'productType' ],
                    displayName: model => `${ formatProductNumber(model.number) } - ${ model.name }`
                }
            }),

        product_version_id: yupFieldType()
            .label(`Product versie`)
            .disabled(id != undefined)
            .controlType(id != undefined ?'input' :'select')
            .handleValueChange((v) => handleProductVersionChange(v as number))
            .disabled(id != undefined || availableProductVersions.length == 0)
            .options(productVersionOptions),

        label_version_id: yupFieldType()
            .label(`Labelversie`)
            .disabled(id != undefined)
            .controlType(id != undefined ?'input' :'select')
            .handleValueChange((v) => handleLabelVersionChange(v as number))
            .disabled(id != undefined || availableLabelVersions.length == 0)
            .options(labelVersionOptions),

        article_id: yupFieldType()
            .label(`Artikel`)
            .disabled(id != undefined)
            .controlType(id != undefined ?'input' :'select')
            .handleValueChange((v) => handleArticleChange(v as number))
            .disabled(id != undefined || availableArticles.length == 0)
            .options(articleOptions),

        label_format: Yup.string()
            .label(`Labelformaat`)
            .hidden(true)
            .defaultValue(currentLabelFormat ?? '0x0')
            .controlType('input')
            .disabled(true)
    });

    /**
     *
     */
    const { validationSchema, setShape } = useSchema<FieldShape>(shape());

    useEffect(() => {
        setShape(shape());
    }, [
        id,
        yupFieldType,
        currentProduct,
        productVersionOptions,
        labelVersionOptions,
        articleOptions,
        labelFormatOptions,
        formatWarning
    ]);


    const initializer = async() => {
        customerArticleLabelHook.getItem(id, {
            with: [ 'product', 'productVersion', 'article', 'labelVersion', 'file', 'customer' ]
        }).then(async cal => {
                if (!cal) {
                    return;
                }
                setCurrentProduct(cal.product);
                setCurrentProductVersion(cal.productVersion ?{
                    ...cal.productVersion,
                    version: cal.product!.version!
                } :undefined);

                setCurrentArticle(cal.article);
                setCurrentLabelVersion(cal.labelVersion);
                if (cal.file) {
                    await getFile(cal.file).then(file => {
                        handleDroppedFiles([ file ], true);
                    });
                }
                if (cal.label_width! + cal.label_height!>0) {
                    setCurrentLabelFormat(GetFormatKeyByLabelDimensions(cal.label_width!, cal.label_height!) as SupportedFormat);
                }
            })
            .finally(() => setShape(shape()));
    };

    /**
     *
     */
    const { formikConfig, formFields } = useForm<
        CustomerArticleLabelModel,
        FieldShape,
        CustomerArticleLabelRelationsBluePrint
    >({
        id,
        validationSchema,
        useHttpHook: useCustomerArticleLabel,
        relations: [ 'product', 'productVersion', 'article', 'labelVersion', 'file', 'customer' ],
        parentId: parentId,
        onSuccess: async(calId) => {
            if (!calId) {
                return;
            }

            let isSuccess = true;
            if (initialFileChanged) {
                isSuccess = await handleFileSave(calId, droppedFile!);
            }

            if (isSuccess) {
                onSuccess?.(id);
                return;
            }

            if (!id) {
                await customerArticleLabelHook.destroy(calId);
            }
        },
        initializer,
        initialized: useAsyncInit(initializer, open),
        morphInitialValues: (cal) => {
            return {
                ...cal,
                product_id: cal?.product ? `${ formatProductNumber(cal.product.number) } - ${ cal.product.name }`: undefined,
                product_version_id: cal?.product ?`V${ cal.product.version }` :undefined,
                label_version_id: cal?.labelVersion ?`V${ cal.labelVersion?.version }` :undefined,
                article_id: cal?.article ?cal.article.number :undefined,
                label_format: cal?.label_width && cal.label_height ? GetFormatKeyByLabelDimensions(cal.label_width!, cal.label_height!) : '0x0'
            }
        },
        morphPayload: (_, dto) => {
            if (!parentId
                || !currentProductVersion?.id
                || !currentProductVersion?.product_id
                || !currentLabelVersion?.id
                || !currentArticle?.id
                || !currentLabelDimensions
            ) {
                return dto;
            }

            let payload: CustomerArticleLabelFields|Partial<CustomerArticleLabelFields> = {
                label_width: currentLabelDimensions.width,
                label_height: currentLabelDimensions.height
            };

            if (!id) {
                payload = {
                    label_width: currentLabelDimensions.width,
                    label_height: currentLabelDimensions.height,
                    customer_id: parentId,
                    product_id: currentProductVersion.product_id,
                    product_version_id: currentProductVersion.id,
                    label_version_id: currentLabelVersion.id,
                    article_id: currentArticle.id
                } as CustomerArticleLabelFields;
            }

            return payload as unknown as FieldShape;
        }
    });

    return <FormModal
        id={ id }
        parentId={ parentId }
        className={ 'md:min-w-[44rem] lg:min-w-[54rem]' }
        resource={ 'Klantlabel' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
        canSave={ canSave }
        grid={ {
            cols: 'grid-cols-1 md:grid-cols-2',
            form: 'col-span-1',
            htmlRight: 'col-span-1',
            gap: 'gap-x-8'
        } }
        htmlRightOfForm={ <div className={ 'sm:!min-h-[36rem] md:!min-h-[38rem] lg:!min-h-[36rem]' }>

            <div className={ 'text-xs' }>
                <h2 className={ 'text- leading-7 text-aqua font-medium' }>Bestand</h2>
                { !droppedFile && <p className={ 'relative mt-1 text-graphite font-light text-xs' }>Geen bestand ge-upload</p> }

                { droppedFile && <ul>
                    <li className={ 'gap-y-1.5 flex content-center items-center' }>
                        <button onClick={ () => handleRemoveFile() } className={ 'w-7 inline-flex items-start content-start' }>
                            <DeleteIcon/>
                        </button>
                        <div>
                            <p>{ droppedFile.name }&nbsp;
                                <small>({ currentRawFormat?.width }x{ currentRawFormat?.height }px) [{ currentRawFormat?.ratio }]</small>
                            </p>
                        </div>

                    </li>
                </ul> }
            </div>

            <DragAndDropField
                onDrop={ handleDroppedFiles }
                accept={ {
                    'image/jpeg': [ '.jpg', '.jpeg' ],
                    'image/png': [ '.png' ],
                    'application/pdf': [ '.pdf' ]
                } }
            />

            <Select
                name={ 'label_format' }
                required={ true }
                disabled={ labelFormatOptions.length == 0 }
                label={ 'Labelformaat' }
                options={ labelFormatOptions }
                onChange={ (v) => handleLabelFormatChange(v.value as SupportedFormat) }
                warning={ formatWarning ?`Formaat matching mislukt ratio = ${ currentRawFormat?.ratio }` :undefined }
            />
        </div> }
    />;
};

export default CustomerArticleLabelForm;
