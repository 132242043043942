import { SoftDeleteModel } from '../../lib/samfe/types/ModelTypes';
import { CustomerModel } from '../customer/CustomerTypes';
import { WithCount } from '../generic/ModelTypeExtensions';
import { WooCommerceImportEntity } from '../woocommerce/import/WooCommerceImportTypes';
import { SaleRowModel } from './pivot/SaleRowTypes';


export type SaleRelationsBluePrint = (
    'saleRows'
    |'saleRows.article'
    |'saleRows.article.product'
    |'saleRows.charge'
    |'saleRows.charge.product'
    |'customer'
    |'financialData'
    |'wcImport'
);

export type SaleStatus = 'backorder'|'to send'|'sent'|'canceled'|'sent with backorders'
export const SaleStatuses: SaleStatus[] = [ 'to send', 'sent', 'backorder', 'canceled', 'sent with backorders' ];
export const SaleStatusTranslation = (status?: SaleStatus) => {
    switch (status?.toLowerCase()) {
        case 'to send':
            return 'Open';
        case 'sent':
            return 'Verzonden';
        case 'backorder':
            return 'Backorder';
        case 'canceled':
            return 'Geannuleerd';
        case 'sent with backorders':
            return 'Verzonden met backorders';
        default:
            return 'Open';
    }
};

export type FinancialSaleData = {
    subtotal: number,
    total: number,
    discount: number,
    discount_percentage: number,
    purchase_price: number,
    profit_margin: number,
    profit_margin_percentage: number
}

export type SaleFields = {
    wc_sale_id?: number,
    wc_import_id?: number
    customer_id?: number,
    status?: SaleStatus,
    customer_note?: string,
    customer_reference?: string|null,
    discount?: number,
    shipped_at?: string,
    ordered_at?: string
}

export type SaleDto = Partial<SaleFields>

export type SaleRelations = {
    saleRows: SaleRowModel[]
    customer: CustomerModel,
    financialData: FinancialSaleData
    wcImport: WooCommerceImportEntity
}

export type SaleModel = SoftDeleteModel&Partial<SaleRelations>&WithCount<Partial<SaleRelations>>&SaleDto
