import React, { FC, useEffect, useMemo, useState } from 'react';
import { ActionButton, DeleteButton } from '../../../lib/samfe/components/Button';
import { useForm } from '../../../lib/samfe/components/Form';
import useAsyncInit from '../../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../../lib/samfe/components/Form/Effects/useSchema';
import Yup from '../../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../../lib/samfe/components/Modal/FormModal/FormModal';
import Alert from '../../../lib/samfe/components/Notifications/Alert';
import { sqlDateTimeToDate } from '../../../lib/samfe/modules/Parse/Date';
import { thousandSeparator } from '../../../lib/samfe/modules/Parse/Number';
import { classNames } from '../../../lib/samfe/modules/Parse/String';
import useCharge from '../../charge/useCharge';
import { getProductTypeUnit } from '../../productType/ProductTypeFunctions';
import { ProductTypeModel } from '../../productType/ProductTypeTypes';
import RepackData, { RepackDataItem } from '../RepackData';
import { RepackingModel } from '../RepackingTypes';
import useRepackingPivotless from '../useRepackingPivotless';
import { RepackBookInDto, RepackBookInModel, RepackBookInRelationsBluePrint } from './RepackBookInTypes';
import RepackBookSummary from './RepackBookSummary';
import useRepackBookIn from './useRepackBookIn';
import {LocationSearchResult} from "../../location/ChargeLocationTypes";
import DynamicSearch from "../../../lib/samfe/components/Form/Field/SelectSearch/DynamicSearch";
import {useNavigate} from "react-router-dom";


const RepackBookInForm: FC<ExtendFormModalProps<RepackBookInDto>> = ({ id, parentId, open, setOpen, onSuccess, onClose }): JSX.Element => {

    const repack = useRepackingPivotless();
    const charge = useCharge();

    const navigate = useNavigate();

    const [ initialRepackOrder, setInitialRepackOrder ] = useState<RepackingModel>();
    const [ repackOrder, setRepackOrder ] = useState<RepackingModel>();

    const [selectedOptions, setSelectedOptions] = React.useState<LocationSearchResult[]>([]);

    const [ parentData, setParentData ] = useState<RepackDataItem>();
    const [ targetData, setTargetData ] = useState<RepackDataItem>();

    const [ parentWasteAmount, setParentWasteAmount ] = useState(0);

    const [ batchCode, setBatchCode ] = useState<string>('');
    const [ expirationDate, setExpirationDate ] = useState<string>();
    const [ pricePerAmount, setPricePerAmount ] = useState<number>(0);
    const [ currentParentAmount, setCurrentParentAmount ] = useState(0);
    const [ currentTargetAmount, setCurrentTargetAmount ] = useState(0);

    const [ productType, setProductType ] = useState<ProductTypeModel>();
    const [ expectedPricePerAmount, setExpectedPricePerAmount ] = useState<number>();
    const [ repackTimeInMinutes, setRepackTimeInMinutes ] = useState<number>(0);

    const { targetAvailable, targetContents, targetAmount } = useMemo(() => ({
        targetAvailable: targetData?.available ?? 0,
        targetContents: targetData?.contents ?? 1,
        targetAmount: targetData?.amount ?? 0
    }), [ targetData?.available, targetData?.contents, targetData?.amount ]);

    const { parentAvailable, parentContents, parentAmount } = useMemo(() => ({
        parentAvailable: parentData?.available ?? 0,
        parentContents: parentData?.contents ?? 1,
        parentAmount: parentData?.amount ?? 0
    }), [ parentData?.available, parentData?.contents, parentData?.amount ]);


    const repackData = useMemo(() => {
        if (!repackOrder) {
            return undefined;
        }

        const repackData = RepackData(repackOrder);
        setParentData(repackData.parent);
        setTargetData(repackData.target);
        setCurrentParentAmount(repackData.parent.amount);
        setCurrentTargetAmount(repackData.target.amount);
        setProductType(repackOrder.parentArticle?.product?.productType);
        return repackData;
    }, [ repackOrder ]);


    useEffect(() => {
        if (repackOrder !== undefined || !initialRepackOrder) {
            return;
        }

        setBatchCode(initialRepackOrder?.batchcode ?? '');
        setExpirationDate(initialRepackOrder.expiration_date ? sqlDateTimeToDate(initialRepackOrder.expiration_date) : undefined);
        setParentWasteAmount(0);
        setRepackOrder(initialRepackOrder);

        if (expectedPricePerAmount === undefined) {
            charge.getList({
                filter: `article_id=${ initialRepackOrder.target_article_id },status!=blocked`,
                select: [ 'price_per_amount' ],
                limit: 1,
                orderBy: 'id',
                order: 'DESC'
            }).then(res => {
                const pricePerAmount = res[0]?.price_per_amount ?? 0;
                setExpectedPricePerAmount(pricePerAmount);
                setPricePerAmount(pricePerAmount);
            });
        } else {
            setPricePerAmount(expectedPricePerAmount ?? 0);
        }


    }, [ initialRepackOrder, repackOrder ]);


    const handleParentAmountChange = (newParentAmount?: number) => {

        if (!newParentAmount || Number.isNaN(newParentAmount) || currentParentAmount === newParentAmount) {
            return;
        }

        const parentPerUnit = newParentAmount * parentContents; // 61*90=5490;
        let targetPerUnit = currentTargetAmount * targetContents; // 90*60=5400
        setCurrentParentAmount(Math.ceil(newParentAmount));

        const wastePerUnit = parentPerUnit - targetPerUnit; // 5490-5400=90
        const newParentWaste = Math.ceil(wastePerUnit / parentContents);
        setParentWasteAmount(newParentWaste);
    };


    const handleTargetAmountChange = (newTargetAmount?: number) => {

        if (!newTargetAmount || Number.isNaN(newTargetAmount) || currentTargetAmount === newTargetAmount) {
            return;
        }

        const targetPerUnit = newTargetAmount * targetContents; // 95* 60 = 5700
        let parentPerUnit = currentParentAmount * parentContents; // 60 * 90 = 5400
        let shouldUpdateParent = false;
        if (targetPerUnit>parentPerUnit) {
            shouldUpdateParent = true;
            parentPerUnit = targetPerUnit; // 5700
        }

        setCurrentTargetAmount(newTargetAmount);

        const wastePerUnit = parentPerUnit - targetPerUnit; // 0
        const wasteConversion = initialRepackOrder?.parentArticle?.is_bulk === true ?parentContents :targetContents;
        const newWasteAmount = Math.floor(wastePerUnit / parentContents); // 420/1
        setParentWasteAmount(newWasteAmount);

        if (shouldUpdateParent) {
            const newParentAmount = Math.ceil(parentPerUnit / parentContents); // 420/1
            setCurrentParentAmount(newParentAmount);
        }
    };


    const handleBatchCodeChange = (batchCode: string = '') => {
        setBatchCode(batchCode);
    };

    const handleExpirationDateChange = (expirationDate?: string) => {
        if (!expirationDate || expirationDate.replaceAll(' ', '') == '') {
            return;
        }
        setExpirationDate(sqlDateTimeToDate(expirationDate));
    };

    const handlePricePerAmountChange = (pricePerAmount: number = 0) => {
        setPricePerAmount(pricePerAmount);
    };

    const handleRepackTimeInMinutes = (repackTimeInMinutes: number = 0) => {
        setRepackTimeInMinutes(repackTimeInMinutes);
    };


    const getUnit = (amount: number, isBulk: boolean) => isBulk
                                                         ?getProductTypeUnit(productType, amount !== 1)
                                                         :(amount === 1 ?'artikel' :'artikelen');


    /**
     *
     */
    const shape = (): Shape<RepackBookInDto> => ({
        repack_id: Yup.number()
            .required()
            .hidden(true)
            .defaultValue(id)
            .label('Naam')
            .controlType('input')
            .inputType('number'),

        parent_amount: Yup.number()
            .required()
            .handleValueChange(handleParentAmountChange)
            .defaultValue(currentParentAmount)
            .label('Aantal uitgevuld van bron charge')
            .description(`Max ${ thousandSeparator(parentAvailable) } ${ getUnit(parentAvailable, initialRepackOrder?.parentArticle?.is_bulk === true) } (${ parentAmount } ${ getUnit(parentAmount, initialRepackOrder?.parentArticle?.is_bulk === true) } initieel)`)
            .controlType('input')
            .steps(1)
            .min(1)
            .max(parentAvailable)
            .inputType('number'),

        waste_amount: Yup.number()
            .hidden(true)
            .required()
            .defaultValue(parentWasteAmount)
            .label('Waarvan waste')
            .description(`Bron charge (max ${ thousandSeparator(parentAvailable) } ${ getUnit(parentAvailable, initialRepackOrder?.parentArticle?.is_bulk === true) })`)
            .controlType('input')
            .steps(1)
            .min(0)
            .max(parentAvailable)
            .inputType('number'),

        target_amount: Yup.number()
            .required()
            .defaultValue(currentTargetAmount)
            .handleValueChange(handleTargetAmountChange)
            .label(`Aantal nieuwe artikelen verwerkt`)
            .description(`Max ${ thousandSeparator(targetAvailable) } ${ getUnit(targetAvailable, initialRepackOrder?.targetArticle?.is_bulk === true) } (${ targetAmount } ${ getUnit(targetAvailable, initialRepackOrder?.targetArticle?.is_bulk === true) } initieel)`)
            .controlType('input')
            .steps(1)
            .min(1)
            .max(targetAvailable)
            .inputType('number'),

        batchcode: Yup.string()
            .required()
            .defaultValue(batchCode)
            .handleValueChange(handleBatchCodeChange)
            .label(`Batchcode voor nieuwe charge`)
            .controlType('input')
            .inputType('text'),

        locations: Yup.number()
            .label('Locaties').hidden(true),

        expiration_date: Yup.string()
            .required()
            .defaultValue(expirationDate ?? '')
            .handleValueChange(handleExpirationDateChange)
            .label('THT voor nieuwe charge')
            .controlType('input')
            .inputType('date'),

        price_per_amount: Yup.number()
            .required()
            .defaultValue(pricePerAmount)
            .handleValueChange(handlePricePerAmountChange)
            .label(`Prijs per stuk voor nieuwe charge`)
            .description('Per 1000 voor bulk')
            .controlType('input')
            .inputType('number'),

        repack_time_in_minutes: Yup.number()
            .required()
            .defaultValue(repackTimeInMinutes)
            .handleValueChange(handleRepackTimeInMinutes)
            .label('Uitvultijd')
            .steps(1)
            .min(1)
            .description('In minuten')
            .controlType('input')
            .inputType('number')
    });

    /**
     *
     */
    const { validationSchema, setShape } = useSchema<RepackBookInDto>(shape());

    useEffect(() => {
        setShape(shape());
    }, [
        repackData,
        currentParentAmount,
        currentTargetAmount,
        batchCode,
        expirationDate,
        pricePerAmount,
        repackTimeInMinutes,
    ]);

    const initializer = async() => {
        await repack.getItem(id, {
            with: [
                'parentCharge.stock',
                'parentArticle.package.packageParts.part',
                'parentArticle.product.productType',
                'targetArticle.package.packageParts.part',
                'targetCharge.chargeLocations.location.group'
            ]
        }).then(setInitialRepackOrder);
        setShape(shape());
    };


    /**
     *
     */
    const { formikConfig, formFields } = useForm<RepackBookInModel, RepackBookInDto, RepackBookInRelationsBluePrint>({
        id,
        parentId,
        validationSchema,
        skipInitialFetch: true,
        staticFormMethod: 'POST',
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useRepackBookIn,
        onSuccess: async (result) => {
            onSuccess?.(result);

            if (result) {
                repack.getItem(id).then(order => {
                    const chargeId = order?.target_charge_id;
                    if (!chargeId) {
                        return;
                    }
                    navigate('/charges/' + chargeId);
                })
            }
        },
        morphPayload: (formikValues, dto) => {
            const newDto = {...dto};

            newDto.parent_amount = currentParentAmount - parentWasteAmount
            newDto.price_per_amount = parseFloat(`${pricePerAmount}`)
            newDto.locations = selectedOptions

            return newDto;
        }
    });


    // @todo switch

    type CanBookIn = {
        type: 'info'|'warning'
        cause: 'invalid_status'|'invalid_conversion'
        valid: boolean,
        message: string,
    }
    const canBookIn: CanBookIn = useMemo(() => {

        const hasCorrectStatus = [ 'processed', undefined ].includes(initialRepackOrder?.status);
        if (!hasCorrectStatus) {
            return {
                type: 'info',
                valid: false,
                message: 'Whoops, Hier hoor je niet bij te kunnen!',
                cause: 'invalid_status'
            } as CanBookIn;
        }

        const currentTargetPerUnit = currentTargetAmount * targetContents;
        const currentParentPerUnit = currentParentAmount * parentContents;


        return {
            type: 'warning',
            valid: currentParentPerUnit>=currentTargetPerUnit,
            message: 'Target charge aantal is meer dan bron aantal!',
            cause: 'invalid_conversion'
        } as CanBookIn;
    }, [ initialRepackOrder?.status, currentTargetAmount, targetContents, currentParentAmount, parentContents ]);

    return <>
        <FormModal
            id={ id }
            onClose={ onClose }
            resource={ `Productietaak ${ id }` }
            open={ open }
            setOpen={ setOpen }
            formikConfig={ formikConfig }
            formFields={ canBookIn.cause !== 'invalid_status' ?formFields :[] }
            operationName={ 'inboeken' }
            className={ 'sm:!w-[72rem] sm:!max-w-none' }

            canSave={ canBookIn.valid }
            withSaveButton={ canBookIn.cause !== 'invalid_status' }
            htmlBeforeForm={ <>
                { !canBookIn.valid && <Alert className={ classNames('absolute ', canBookIn.cause !== 'invalid_status' ?'w-[calc(50%-2.7rem)]' :'w-[calc(100%-2.9rem)]') } type={ canBookIn.type } message={ canBookIn.message }/> }
                <div className={ 'h-10' }></div>
                { canBookIn.cause !== 'invalid_status' && currentParentAmount !== parentAvailable && <ActionButton
                    style={ 'tertiary' }
                    icon={ '' }
                    text={ 'Gebruik alles' }
                    className={ 'absolute left-[37.75%] text-sm font-normal top-[6.45rem]' }
                    onClick={ () => {
                        handleParentAmountChange(parentAvailable);
                    } }/> }

                {/* target amount */ }
                { canBookIn.cause !== 'invalid_status' && currentTargetAmount !== Math.floor((currentParentAmount * parentContents) / targetContents) && <ActionButton
                    style={ 'tertiary' }
                    icon={ '' }
                    text={ 'Match bron' }
                    className={ 'absolute left-[38.75%] text-sm font-normal top-[13.2rem]' }
                    onClick={ () => {
                        handleTargetAmountChange(Math.floor((currentParentAmount * parentContents) / targetContents));
                    } }/> }

                {/* batch code */ }
                { canBookIn.cause !== 'invalid_status' && initialRepackOrder?.parentCharge?.batchcode !== batchCode && <ActionButton
                    style={ 'tertiary' }
                    icon={ '' }
                    text={ 'Match bron' }
                    className={ 'absolute left-[38.75%] text-sm font-normal top-[18.45rem]' }
                    onClick={ () => {
                        handleBatchCodeChange(initialRepackOrder?.parentCharge?.batchcode);
                    } }/> }


                {/* Expiration date */ }
                { canBookIn.cause !== 'invalid_status' && expirationDate !== sqlDateTimeToDate(initialRepackOrder?.parentCharge?.expiration_date) && <ActionButton
                    style={ 'tertiary' }
                    icon={ '' }
                    text={ 'Match bron' }
                    className={ 'absolute left-[38.75%] text-sm font-normal top-[23.75rem]' }
                    onClick={ () => {
                        handleExpirationDateChange(sqlDateTimeToDate(initialRepackOrder?.parentCharge?.expiration_date));
                    } }/> }

            </> }
            htmlRightOfForm={ <>
                { repackOrder && canBookIn.cause !== 'invalid_status' && <RepackBookSummary repackOrder={ repackOrder } currentValues={ {
                    targetAmount: currentTargetAmount,
                    parentAmount: currentParentAmount,
                    expirationDate: expirationDate ?? '',
                    wasteAmount: parentWasteAmount,
                    currentBatchCode: batchCode,
                    repackTimeInMinutes,
                    // @todo: Location list and display logic
                } }/> }
            </> }

            htmlAfterForm={ <>
                { canBookIn.cause !== 'invalid_status' && <div className={ 'fixed bottom-6 ' }>
                    <DeleteButton text={ 'Reset' } onClick={ () => {
                        setRepackOrder(undefined);
                    } }/>
                </div> }
                <div className={'max-w-[33.5rem]'}>
                    <DynamicSearch label={'Locaties'} config={
                        {
                            searchResource: "search/locations",
                            values: selectedOptions,
                            setValues: setSelectedOptions,
                            initialDisplay: undefined,
                        }
                    } />
                </div>
            </> }
            grid={ {
                form: 'col-span-6',
                htmlRight: 'col-span-6',
                cols: 'grid-cols-12'
            } }
        />

    </>;
};

export default RepackBookInForm;
