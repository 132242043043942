import React, { FC, useMemo } from 'react';
import GroupForm from './GroupForm';
import { GroupModel, GroupRelationsBluePrint } from './GroupTypes';
import useGroup from './useGroup';
import {ExtendTableProps} from "../../components/table/types/Table";
import Table from "../../components/table";
import {TableColumns} from "../../components/table/types/TableRow";


type Props = Required<ExtendTableProps>

const GroupTable: FC<Props> = () => {

    const httpHook = useGroup();

    const rows = useMemo((): TableColumns<GroupModel, GroupRelationsBluePrint>[] => [
        {
            header: {
                children: 'Groep',
                sortCol: 'name'
            },
            column: (item) => ({
                children: item.name
            }),
            type: 'text',
        },
        {
            header: {
                children: 'Korte naam',
                sortCol: 'key'
            },
            column: (item) => ({
                children: item.key
            }),
            type: 'text',
        },
    ], []);

    return (<>
        <Table
            id={ 'groups' }
            rows={ rows }
            http={ {
                hook: httpHook,
                searchCols: ['name', 'key'],
                sortCol: 'name',
                sortDir: 'ASC'
            } }
            forms={ {
                edit: {
                    Form: GroupForm,
                    id: (record) => record.id,
                },
                // archive: {
                //     id: (record) => record.id,
                //     itemName: (record) => `${ record.name }`,
                //     resourceName: () => 'Gebouw'
                // },
            } }
            styling={{
              variation: 'inline'
            }}
        />
    </>
    );
};

export default GroupTable;
