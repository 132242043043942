import React, { FC, useMemo, useState } from 'react';
import Table from '../../components/table';
import { ExtendTableProps } from '../../components/table/types/Table';
import { TableFilterType } from '../../components/table/types/TableFilter';
import { TableColumns } from '../../components/table/types/TableRow';
import { dispatchHttpEvent } from '../../events/Http.event';
import Modalfy from '../../lib/samfe/components/Modal/Modalfy';
import { batchCodeLabel } from '../charge/ChargeFunctions';
import useFileHandler from '../document/useFileHandler';
import RepackBookInForm from './pivot/RepackBookInForm';
import RepackData from './RepackData';
import RepackingForm from './RepackingForm';
import RepackingReceipt from './RepackingReceipt';
import { RepackingModel, RepackingRelationsBluePrint, RepackingStatuses, RepackStatusTranslation } from './RepackingTypes';
import useRepackingPivotless from './useRepackingPivotless';


type Props = Required<ExtendTableProps>

const RepackingTable: FC<Props> = (): JSX.Element => {

    const httpHook = useRepackingPivotless();
    const { getDocument } = useFileHandler();

    const [ currentRepackOrder, setCurrentRepackOrder ] = useState<RepackingModel>();
    const [ openReceipt, setOpenReceipt ] = useState(false);
    const [ openBookInModal, setOpenBookInModal ] = useState(false);
    const [ openEditModal, setOpenEditModal ] = useState(false);


    const rows = useMemo((): TableColumns<RepackingModel, RepackingRelationsBluePrint>[] => [
        {
            header: {
                children: 'Taaknummer',
                sortCol: 'id'
            },
            column: (item) => ({
                children: item.id,
                onClick: (item) => {
                    setCurrentRepackOrder(item);
                    setOpenReceipt(true);
                }
            }),
            type: 'id'
        },
        {
            header: {
                children: 'Product'
            },
            column: (item) => ({
                children: item.parentArticle?.product?.name,
                linkTo: (item) => `/products/${ item.parentArticle?.product?.id }`
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Artikel (Bron)'
            },
            column: (item) => ({
                children: item.parentArticle?.number,
                linkTo: (item) => `/articles/${ item.parentArticle?.id }`
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Charge (Bron)'
            },
            column: (item) => ({
                children: batchCodeLabel(item.parentCharge),
                linkTo: (item) => `/charges/${ item.parentCharge?.id }`
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Aantal (Bron)'
            },
            column: (item) => ({
                children: RepackData(item).parent.amount
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Artikel (Doel)'
            },
            column: (item) => ({
                children: item.targetArticle?.number,
                linkTo: (item) => `/articles/${ item.targetArticle?.id }`
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Charge (Doel)'
            },
            column: (item) => ({
                children: item.targetCharge ? batchCodeLabel(item.targetCharge) : item.batchcode,
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Aantal (Doel)'
            },
            column: (item) => ({
                children: RepackData(item).target.amount
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Verwerkingsdatum',
                sortCol: 'repack_date'
            },
            column: (item) => ({
                children: item.repack_date
            }),
            type: 'date'
        },
        {
            header: {
                children: 'Status',
                sortCol: 'status'
            },
            column: (item) => ({
                children: RepackStatusTranslation(item.status)
            }),
            type: 'text'
        }
    ], []);

    return <>
        <Table
            id={ 'repack-orders' }
            rows={ rows }
            http={ {
                hook: httpHook,
                searchCols: [
                    'parentArticle.product.name',
                    'parentArticle.number',
                    'targetArticle.number',
                    'targetArticle.product.name',
                    'id'
                ],
                with: [
                    'parentArticle.product.productType',
                    'parentArticle.package.packageParts.part',
                    'parentCharge.chargeLocations.location.group',
                    'targetArticle.package.packageParts.part',
                    'targetCharge.chargeLocations.location.group'
                ],
                sortCol: 'repack_date',
                sortDir: 'ASC',
                filters: [
                    {
                        column: 'status',
                        displayName: 'Actie vereist',
                        operator: '!=',
                        value: 'booked',
                        default: true
                    },
                    ...RepackingStatuses.map(status =>
                        ({
                            displayName: RepackStatusTranslation(status),
                            column: 'status',
                            operator: '=',
                            value: status
                        }) as TableFilterType<RepackingModel>
                    )
                ]
            } }
            forms={ {
                edit: {
                    Form: RepackingForm,
                    id: (record) => record.id,
                    disableForRow: (item) => item.status == 'booked'
                },
                // archive: {
                //     id: (record) => record.id,
                //     itemName: (record) => `${ record.id }`,
                //     resourceName: () => 'Productietaak',
                //     disableForRow: (item) => item.status == 'booked'
                // },
                actions: [
                    {
                        title: 'Inboeken',
                        id: (item) => item.id,
                        parentId: (item) => item.parent_charge_id,
                        Form: RepackBookInForm,
                        disableForRow: (item) => item.status != 'processed',
                        onSuccess: () => {

                        }
                    }
                ]
            } }
            callbacks={ [
                {
                    title: 'Quick preview',
                    onClick: (item) => {
                        setCurrentRepackOrder(item);
                        setOpenReceipt(true);
                    }
                },
                {
                    title: 'Uitvul formulier',
                    disableForRow: (item) => item.status == 'backorder',
                    onClick: (item) => {
                        getDocument(item.id, 'repackage');
                    }
                }
            ] }
        />

        { openReceipt && currentRepackOrder && <Modalfy
            omitCloseWarningModal={ true }
            open={ openReceipt }
            setOpen={ setOpenReceipt }
            onClose={ dispatchHttpEvent }
        >
            <RepackingReceipt
                repackOrder={ currentRepackOrder }
                isInline={ false }
                onClose={ () => setOpenReceipt(false) }
                bookInAction={ () => {
                    setOpenBookInModal(true);
                } }

                editAction={ () => {
                    setOpenEditModal(true);
                } }
            />
        </Modalfy> }

        { openEditModal && <RepackingForm
            id={ currentRepackOrder?.id }
            open={ openEditModal }
            setOpen={ setOpenEditModal }
            parentId={ currentRepackOrder?.parent_charge_id }
            onSuccess={ dispatchHttpEvent }
        /> }

        { openBookInModal && currentRepackOrder?.parent_charge_id &&
            <RepackBookInForm
                id={ currentRepackOrder?.id }
                parentId={ currentRepackOrder?.parent_charge_id }
                open={ openBookInModal }
                setOpen={ setOpenBookInModal }
                onSuccess={ dispatchHttpEvent }
            /> }
    </>;
};

export default RepackingTable;