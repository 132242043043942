import React, { FC, useMemo } from 'react';
import Table from '../../../../components/table';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import { TableColumns } from '../../../../components/table/types/TableRow';
import { ArticleProducerModel, ArticleProducerRelationsBluePrint } from '../../../article/pivot/ArticleProducer/ArticleProducerTypes';
import ProducerArticleForm from './ProducerArticleForm';
import useProducerArticle from './useProducerArticle';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>
const ProducerArticleTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useProducerArticle(parentId);


    const rows = useMemo((): TableColumns<ArticleProducerModel, ArticleProducerRelationsBluePrint>[] => [
        {
            header: {
                children: 'Artikel'
            },
            column: (item) => ({
                children: item.article?.number
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Product'
            },
            column: (item) => ({
                children: item.article?.product?.name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Verpakking'
            },
            column: (item) => ({
                children: item.article?.package?.name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Artikel leverancier naam',
                sortCol: 'custom_name'
            },
            column: (item) => ({
                children: item.custom_name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Actief'
            },
            column: (item) => ({
                children: item.article?.active ?'Ja' :'Nee'
            }),
            type: 'text'
        }
    ], []);

    return <>
        <Table
            id={ 'articles' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `/articles/${ item.article_id }`
            } }
            http={ {
                hook: httpHook,
                searchCols: [ 'article.number', 'article.product.name', 'article.package.name', 'custom_name' ],
                with: [
                    'article.product',
                    'article.package'
                ],
                sortCol: 'custom_name',
                sortDir: 'ASC'
            } }
            forms={ {
                edit: {
                    Form: ProducerArticleForm,
                    parentId: (item) => item.producer_id,
                    id: (item) => item.id
                },
                // archive: {
                //     id: (record) => record.id,
                //     itemName: (record) => `${ record.custom_name ?? record.article?.number ?? 'artikel' }`,
                //     resourceName: () => 'Artikel van leverancier'
                // },
                actions: []
            } }
            styling={{
              variation: 'inline'
            }}
            callbacks={ [] }
        />
    </>;
};
export default ProducerArticleTable;