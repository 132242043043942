import React, { FC, useMemo } from 'react';
import Table from '../../components/table';
import { ExtendTableProps } from '../../components/table/types/Table';
import { TableColumns } from '../../components/table/types/TableRow';
import { ProfitLossCauseDisplayName, ProfitLossModel, ProfitLossRelationsBluePrint } from './ProfitLossTypes';
import useProfitLoss from './useProfitLoss';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ProfitLossTable: FC<Props> = ({ parentId }) => {

    const httpHook = useProfitLoss();

    const rows = useMemo((): TableColumns<ProfitLossModel, ProfitLossRelationsBluePrint>[] => [
        {
            header: {
                children: 'Oorzaak',
                sortCol: 'cause'
            },
            column: (item) => ({
                children: ProfitLossCauseDisplayName(item.cause)
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Aantal',
                sortCol: 'amount'
            },
            column: (item) => ({
                children: item.amount
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Aanmaakdatum',
                sortCol: 'created_at'
            },
            column: (item) => ({
                children: item.created_at
            }),
            type: 'date'
        },
        {
            header: {
                children: 'Aanvullende informatie',
                sortCol: 'comment'
            },
            column: (item) => ({
                children: item.comment
            }),
            type: 'text'
        }
    ], []);

    return (<>
            <Table
                id={ 'profit-loss' }
                rows={ rows }
                http={ {
                    hook: httpHook,
                    searchCols: [ 'cause', 'comment' ],
                    filterConfig: {
                        hideArchived: true
                    },
                    filter: {
                        column: 'charge_id',
                        value: parentId ?? 0
                    },
                    disableDeArchiving: true
                } }
            />
        </>
    );
};

export default ProfitLossTable;
